<script>
	import { uid, dateFormat } from "@xbs/lib-core-dom";

	import Text from "../Text.svelte";
	import Dropdown from "../Dropdown.svelte";
	import Calendar from "./Calendar.svelte";

	export let value;
	export let id = uid();

	let node, area;

	function cancel() {
		area = null;
	}

	let current = value ? new Date(value) : new Date();

</script>

<svelte:window on:scroll={cancel} />

<div class="layout" bind:this={node} on:click={() => (area = node)}>
	<Text
		value={value ? dateFormat(value) : value}
		{id}
		readonly={true}
		inputStyle={'cursor: pointer;'} />
	<i class="icon wxi-calendar" />
	{#if area}
		<Dropdown {cancel} width={'unset'}>
			<Calendar {cancel} bind:value bind:current />
		</Dropdown>
	{/if}
</div>

<style>
	.layout {
		position: relative;
	}
	.icon {
		position: absolute;
		right: 4px;
		top: 8px;
	}</style>
