<script>
	import { delegateClick } from "@xbs/lib-core-dom";
	import Button from "./Button.svelte";
	import Dropdown from "./Dropdown.svelte";

	export let options = [];
	export let selected = [];
	export let canEdit = false;
	export let canDelete = true;
	export let edit;
	export let title = "";

	let used = [],
		notUsed = [];
	$: {
		used = [];
		notUsed = [];
		options.forEach(a => {
			if (!selected || selected.indexOf(a.id) === -1) notUsed.push(a);
			else used.push(a);
		});
	}

	function clickToSelect(id) {
		selected = [...(selected || []), id];
		popup = null;
	}
	function remove(id) {
		selected = selected.filter(a => a !== id);
		return false;
	}
	function editOption(id) {
		if (canEdit) edit(id);
		return false;
	}

	let listClicks = {
		remove: remove,
		// click: editOption,
		edit: editOption,
	};
	let popupClicks = {
		click: clickToSelect,
	};

	let popup = null;
	function showDropdown(ev) {
		popup = ev.target.getBoundingClientRect();
	}

</script>

<div class="layout">
	<div class="wx-list" use:delegateClick={listClicks}>
		{#each used as option (option.id)}
			<div class="wx-list-item" data-id={option.id}>
				<slot {option} />
				{#if canEdit}
					<i
						class="wx-list-icon wx-hover wxi-edit"
						data-action="edit"
						title="Edit" />
				{/if}
				{#if canDelete}
					<i
						class="wx-list-icon wx-hover wxi-delete"
						data-action="remove"
						title="Delete" />
				{/if}
			</div>
		{/each}
	</div>

	{#if canDelete && notUsed.length}
		<Button type="link" click={showDropdown}>Add {title}</Button>
	{/if}

	{#if popup}
		<Dropdown area={popup} cancel={() => (popup = null)}>
			<div class="wx-list list">
				{#each notUsed as option (option.id)}
					<div
						class="wx-list-item"
						data-id={option.id}
						use:delegateClick={popupClicks}>
						<slot {option} />
					</div>
				{/each}
			</div>
		</Dropdown>
	{/if}
</div>

<style>
	.layout {
		position: relative;
	}

	.list {
		width: 320px;
		margin: 10px;
	}</style>
