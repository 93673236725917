<script lang="ts">var _a;
import { getContext, setContext } from "svelte";
import { en, locale } from "@xbs/lib-kanban";
const i18nContext = getContext("wx-i18n");
if (!i18nContext) {
    setContext("wx-i18n", locale(en));
}
else if (!((_a = i18nContext === null || i18nContext === void 0 ? void 0 : i18nContext.data) === null || _a === void 0 ? void 0 : _a.kanban)) {
    i18nContext.extend(en);
}
</script>

<div class="toolbar">
	<slot />
</div>

<style>
	.toolbar {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: var(--wx-kanban-toolbar-justify);

		height: var(--wx-kanban-toolbar-height);
		min-height: var(--wx-kanban-toolbar-height);

		width: 100%;
		padding: var(--wx-default-padding);
		background: #ffffff;

		border-bottom: var(--wx-default-border);
		color: var(--wx-font-color);
	}</style>
