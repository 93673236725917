<script>
	import Button from "./Button.svelte";

	export let options;
	export let value = options[0].id;

</script>

<div class="toggle">
	{#each options as opt (opt.id)}
		<Button
			shape="square"
			click={() => (value = opt.id)}
			type={opt.id === value ? 'selected' : ''}>
			{opt.label}
		</Button>
	{/each}
</div>

<style>
	.toggle {
		margin-bottom: 3px;
		border-radius: 4px;
		overflow: hidden;
		display: inline-block;
	}</style>
