<script>
	export let data;

	const fileSize = ["b", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb"];

	function removeAll() {
		data.set([]);
	}

	function remove(id) {
		data.update(v => v.filter(i => i.id !== id));
	}

	function formatSize(size) {
		let index = 0;
		while (size > 1024) {
			index++;
			size = size / 1024;
		}
		return Math.round(size * 100) / 100 + " " + fileSize[index];
	}

</script>

{#if $data.length}
	<div class="layout">
		<div class="header"><i class="wxi-close" on:click={removeAll} /></div>
		<div class="list">
			{#each $data as obj (obj.id)}
				<div class="row">
					<div class="file-icon" />
					<div class="name">{obj.name}</div>
					{#if obj.file}
						<div class="size">{formatSize(obj.file.size)}</div>
					{/if}
					<div class="controls">
						{#if obj.status === 'client'}
							<i class="icon wxi-spin wxi-loading" />
						{:else if obj.status === 'error'}
							<i class="icon wxi-alert" />
							<i
								class="icon wxi-close"
								on:click={() => remove(obj.id)} />
						{:else if !obj.status || obj.status === 'server'}
							<i class="icon wxi-check" />
							<i
								class="icon wxi-close"
								on:click={() => remove(obj.id)} />
						{/if}
					</div>
				</div>
			{/each}
		</div>
	</div>
{/if}

<style>
	.layout {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	i {
		cursor: pointer;
	}
	.header {
		padding: 10px;
		border-bottom: 1px solid var(--wx-border-color);
		text-align: right;
	}

	.list {
		overflow: auto;
	}

	.row {
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 10px;
		border-bottom: 1px solid var(--wx-border-color);
	}

	.name {
		flex: 1;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.controls {
		align-self: flex-end;
	}

	.row .icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 14px;
		height: 14px;
	}

	.row .wxi-close {
		display: none;
	}

	.row .wxi-check,
	.row .wxi-alert {
		display: flex;
	}

	.row:hover .wxi-close {
		display: flex;
	}

	.row:hover .wxi-check,
	.row:hover .wxi-alert {
		display: none;
	}</style>
