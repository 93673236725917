<script lang="ts">import { isImage } from "@xbs/lib-kanban";
import { wx } from "@xbs/svelte-wx";
import Icon from "./Icon.svelte";
;
;
const { Button } = wx;
export let data;
const fileSize = ["b", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb"];
function removeAll() {
    data.set([]);
}
function remove(id) {
    data.update(v => v.filter(i => i.id !== id));
}
function formatSize(size) {
    let index = 0;
    while (size > 1024) {
        index++;
        size = size / 1024;
    }
    return Math.round(size * 100) / 100 + " " + fileSize[index];
}
function isPreview(obj) {
    var _a, _b;
    const ext = (_a = obj === null || obj === void 0 ? void 0 : obj.url) === null || _a === void 0 ? void 0 : _a.split(".").pop();
    const previewExt = (_b = obj === null || obj === void 0 ? void 0 : obj.previewURL) === null || _b === void 0 ? void 0 : _b.split(".").pop();
    return isImage(previewExt) || isImage(ext);
}
function handleMakeCover(id) {
    data.update(v => {
        return v.map(obj => {
            if (obj.id === id) {
                return Object.assign(Object.assign({}, obj), { isCover: true });
            }
            else {
                delete obj.isCover;
                return obj;
            }
        });
    });
}
function handleRemoveCover() {
    data.update(v => {
        return v.map(obj => {
            const copy = Object.assign({}, obj);
            delete copy.isCover;
            return copy;
        });
    });
}
</script>

{#if $data.length}
	<div class="layout">
		<div class="header">
			<i class="far fa-times" on:click={removeAll} />
		</div>
		<div class="list">
			{#each $data as obj (obj.id)}
				<div class="row">
					<div class="file-icon">
						{#if isPreview(obj)}
							<div
								class="thumb"
								style="background-image: url('{obj.previewURL || obj.url}')" />
						{:else}
							<Icon name="paperclip" size={20} />
						{/if}
					</div>
					<div class="name">{obj.name}</div>
					{#if obj.file}
						<div class="size">{formatSize(obj.file.size)}</div>
					{/if}
					<div class="controls">
						{#if obj.status === 'client'}
							<Icon name="loading" spin />
						{:else if obj.status === 'error'}
							<Icon name="alert" />
							<Icon name="delete" click={() => remove(obj.id)} />
						{:else if !obj.status || obj.status === 'server'}
							<div class="hidden">
								<a
									class="upload-link"
									href={obj.url}
									download={obj.name}
									target="_blank"
									rel="noreferrer nofollow noopener">
									<Icon name="external" clickable />
								</a>
								<Icon
									name="delete"
									click={() => remove(obj.id)} />

								{#if isPreview(obj)}
									{#if !obj.isCover}
										<Button
											click={() => handleMakeCover(obj.id)}>
											Make cover
										</Button>
									{:else}
										<Button click={handleRemoveCover}>
											Remove cover
										</Button>
									{/if}
								{/if}
							</div>
						{/if}
					</div>
				</div>
			{/each}
		</div>
	</div>
{/if}

<style>
	.layout {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	i {
		cursor: pointer;
	}
	.header {
		padding: 10px;
		border-bottom: 1px solid #ddd;
		text-align: right;
	}

	.list {
		overflow: auto;
	}

	.row {
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 10px;
		border-bottom: 1px solid #ddd;
	}

	.name {
		flex: 1;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.controls {
		display: flex;
	}
	.hidden {
		display: none;
	}
	.row:hover .hidden {
		display: flex;
	}
	.file-icon {
		min-width: 40px;
		min-height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.thumb {
		width: 40px;
		height: 40px;
		max-width: 40px;
		max-height: 40px;

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.upload-link {
		text-decoration: none;
		display: flex;
	}</style>
