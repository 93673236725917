<script>
	import { locateID } from "@xbs/lib-core-dom";

	import Dropdown from "./Dropdown.svelte";

	export let value = null;
	export let options = [];

	let popup, list, item;
	let selected;
	let index;

	let navItem;
	let navInd;

	$: {
		selected = options.find(a => a.id === value);
		index = value ? options.findIndex(a => a.id === value) : 0;
		navItem = popup ? options[index] : null;
	}

	function click() {
		popup = true;
	}

	function select(ev) {
		const id = locateID(ev);
		if (id) value = id;
		popup = null;
	}

	function keySelect() {
		value = navItem.id;
		navInd = null;
		popup = null;
	}

	function navigate(dir) {
		navInd = navInd || navInd === 0 ? navInd : index;
		navInd += dir;

		if (navInd === options.length) {
			navInd = 0;
		} else if (navInd < 0) {
			navInd = options.length - 1;
		}

		navItem = options[navInd];

		let height;

		// if (dir === 1) {
		height = item.clientHeight * (navInd + 1) - list.clientHeight;
		// } else {
		// }

		list.scrollTo(0, height);
	}

	function keydown(ev) {
		switch (ev.code) {
			case "Space":
				popup = !popup;
				break;

			case "Enter":
				if (popup) {
					keySelect();
				} else {
					popup = true;
				}
				break;

			case "ArrowDown":
				if (popup) {
					navigate(1);
				} else {
					popup = true;
				}
				break;

			case "ArrowUp":
				if (popup) {
					navigate(-1);
				}
				break;

			case "Escape":
				navInd = null;
				popup = null;
				break;

			default:
				break;
		}
	}

	function cancel() {
		popup = null;
	}

</script>

<div
	class="select"
	class:active={popup}
	on:click={click}
	on:keydown={keydown}
	tabindex="0">
	<div class="label" bind:this={item}>
		{#if selected}
			<slot option={selected} />
		{:else}&nbsp;{/if}
	</div>
	<i class="icon wxi-angle-down" />

	{#if popup}
		<Dropdown {cancel}>
			<div
				class="list"
				bind:this={list}
				on:click|stopPropagation={select}>
				{#each options as data}
					<div
						class="item"
						class:selected={value && value === data.id}
						class:navigate={navItem && navItem.id === data.id}
						data-id={data.id}>
						<slot option={data} />
					</div>
				{/each}
			</div>
		</Dropdown>
	{/if}
</div>

<style>
	.select {
		position: relative;
		width: 300px;
		min-height: 30px;
		border: var(--wx-input-border);
		font: var(--wx-font);
		color: var(--wx-color);
		cursor: pointer;
	}

	.select.active {
		border-color: var(--wx-input-focus-color);
	}

	.label {
		padding: 8px;
		cursor: pointer;
	}

	.list {
		max-height: 250px;
		background-color: var(--wx-back-color);
		overflow-y: auto;
	}

	.item {
		padding: 8px;
		cursor: pointer;
	}
	.item.selected,
	.item:hover {
		background-color: var(--wx-secondary-back-color);
	}

	.item.navigate,
	.item:hover {
		color: var(--wx-primary-font-color);
		background-color: var(--wx-primary-color);
	}

	.icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 10px;
	}</style>
