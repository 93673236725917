<script>
	import { uid } from "@xbs/lib-core-dom";

	export let id = uid();
	export let checked;

</script>

<label class="switch">
	<input type="checkbox" bind:checked {id} />
	<span class="slider" />
</label>

<style>
	.switch {
		position: relative;
		display: inline-block;
		width: 50px;
		height: 24px;
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 30px;
		background-color: var(--wx-secondary-back-color);
		transition: 0.4s;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 22px;
		width: 22px;
		left: -2px;
		bottom: 0;
		border-radius: 50%;
		border: var(--wx-input-border);
		background-color: var(--wx-back-color);
		transition: 0.4s;
	}

	input:checked + .slider {
		background-color: var(--wx-primary-color);
	}

	input:checked + .slider:before {
		transform: translateX(32px);
	}</style>
