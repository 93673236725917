<script lang="ts">export let label = "";
export let min = 0;
export let max = 100;
export let value = 0;
export let showValue = true;
let progress = "0";
let bgStyle = "";
const inactive = "#dbdbdb";
$: {
    progress = Math.floor(((value - min) / (max - min)) * 100) + "%";
    bgStyle = `background: linear-gradient(90deg, var(--wx-primary-color) 0% ${progress}, ${inactive} ${progress} 100%);`;
}
</script>

<div class="layout">
	<div class="label">{label}</div>
	<div class="wrap">
		<div class="progress" style={bgStyle} />
		{#if showValue}
			<div class="value">{value}%</div>
		{/if}
	</div>
</div>

<style>
	.layout {
		margin-bottom: 5px;
		min-width: 100%;
	}

	.label {
		display: block;
		margin-bottom: 5px;
		font: var(--wx-label-font);
		color: var(--wx-label-font-color);
	}

	.wrap {
		display: flex;
		align-items: center;
	}

	.progress {
		width: 100%;
		background-color: #dbdbdb;
		border-radius: 4px;
		height: var(--wx-progress-height);
		min-height: var(--wx-progress-height);
		max-height: var(--wx-progress-height);
	}

	.value {
		min-width: 35px;
		text-align: end;
	}</style>
