<script>
	import { onMount } from "svelte";
	import Confirm from "./Confirm.svelte";

	export let title;
	export let value;
	export let ok;
	export let cancel;

	let input;
	onMount(() => {
		input.select();
		input.focus();
	});

	function hotkeys(ev) {
		if (ev.key == "Escape") {
			cancel();
		} else if (ev.key == "Enter") {
			ok(value, ev);
		}
	}

</script>

<Confirm {title} ok={ev => ok(value, ev)} {cancel}>
	<input bind:this={input} bind:value on:keydown={hotkeys} />
</Confirm>

<style>
	input {
		outline: none;
		border: none;
		width: 100%;
		font-size: 16px;
		padding: 0 10px;
	}
	input:focus {
		border-bottom: 1px solid var(--wx-input-focus-color);
	}</style>
