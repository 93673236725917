<script>
	import { uid } from "@xbs/lib-core-dom";
	import { onMount, createEventDispatcher } from "svelte";

	export let value = "";
	export let id = uid();
	export let readonly = false;
	export let focus = false;
	export let type = "text";
	export let placeholder = null;
	export let disabled = false;
	export let inputStyle = "";

	const dispatch = createEventDispatcher();

	let input;
	if (focus) {
		onMount(() => input.focus());
	}

</script>

{#if type == 'password'}
	<input
		bind:value
		bind:this={input}
		{id}
		{readonly}
		{disabled}
		{placeholder}
		type="password"
		style={inputStyle}
		on:input={() => dispatch('input', { value })} />
{:else if type == 'number'}
	<input
		bind:value
		bind:this={input}
		{id}
		{readonly}
		{disabled}
		{placeholder}
		type="number"
		style={inputStyle}
		on:input={() => dispatch('input', { value })} />
{:else}
	<input
		bind:value
		bind:this={input}
		{id}
		{readonly}
		{disabled}
		{placeholder}
		style={inputStyle}
		on:input={() => dispatch('input', { value })} />
{/if}

<style>
	input {
		padding: var(--wx-input-padding);
		outline: none;
		flex: 1;
		font: var(--wx-font);
		color: var(--wx-color);
		width: 100%;
		box-sizing: border-box;

		border: var(--wx-input-border);
		border-radius: var(--wx-input-border-radius);
		background-color: transparent;
	}
	input:focus {
		border: 1px solid var(--wx-input-focus-color);
	}</style>
