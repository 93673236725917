<script lang="ts">import { wx } from "@xbs/svelte-wx";
const { Button, Text, Area, Field, Select, MultiSelect, Combo, DatePicker, ColorPicker, Slider, } = wx;
import { createEventDispatcher, getContext } from "svelte";
import { elementsIds, format } from "@xbs/lib-kanban";
import { form } from "@xbs/lib-svelte";
;
import FilesControl from "../lib/FilesControl.svelte";
import UserIcon from "../lib/UserIcon.svelte";
const dispatch = createEventDispatcher();
export let selectedCard;
export let editorShape;
export let autoSave = true;
const _ = getContext("wx-i18n").getGroup("kanban");
let formValues;
function saveForm() {
    dispatch("action", {
        action: "update-card",
        data: Object.assign({}, formValues),
    });
}
const values = form({}, val => {
    formValues = val;
    if (autoSave) {
        saveForm();
    }
});
$: values.reset(setDefaults(selectedCard));
function setDefaults(obj) {
    const copy = Object.assign({}, obj);
    editorShape.forEach(a => {
        if (typeof copy[a.key] === "undefined") {
            copy[a.key] =
                a.type === "files" ? [] : a.type === "date" ? null : "";
        }
    });
    return copy;
}
function handleClose() {
    dispatch("action", {
        action: "unselect-card",
        data: Object.assign({}, selectedCard),
    });
}
</script>

<div
	class="editor"
	class:editor-open={selectedCard}
	data-kanban-id={elementsIds.editor}
	on:click|stopPropagation>
	<div class="editor-controls">
		<Button block click={handleClose}>{_('Close')}</Button>
		{#if !autoSave}
			<Button block type="primary" click={saveForm}>{_('Save')}</Button>
		{/if}
	</div>

	{#if selectedCard}
		{#each editorShape as field (field.id)}
			{#if field.type === 'text'}
				<Field label={_(field.label)} position="top" let:id>
					<Text {id} bind:value={$values[field.key]} focus />
				</Field>
			{:else if field.type === 'textarea'}
				<Field label={_(field.label)} position="top" let:id>
					<Area {id} bind:value={$values[field.key]} />
				</Field>
			{:else if field.type === 'progress'}
				<Field label={field.label} position="top" let:id>
					<Slider {id} bind:value={$values[field.key]} min={0} />
				</Field>
			{:else if field.type === 'combo'}
				<Field label={field.label} position="top" let:id>
					<Combo
						{id}
						options={field.options}
						bind:value={$values[field.key]}
						let:option>
						<div class="combo-option">
							{#if option?.color}
								<div
									class="color"
									style="background:{option?.color}" />
							{/if}
							{option.label}
						</div>
					</Combo>
				</Field>
			{:else if field.type === 'select'}
				<Field label={_(field.label)} position="top" let:id>
					<Select
						{id}
						bind:value={$values[field.key]}
						options={field.options} />
				</Field>
			{:else if field.type === 'color'}
				<Field label={_(field.label)} position="top" let:id>
					<ColorPicker
						{id}
						bind:value={$values[field.key]}
						colors={field?.colors} />
				</Field>
			{:else if field.type === 'multiselect'}
				<Field label={field.label} position="top" let:id>
					<MultiSelect
						{id}
						bind:selected={$values[field.key]}
						options={field.options}
						canDelete
						let:option>
						<div class="multiselect-option">
							<UserIcon data={option} />
							<span class="multiselect-label">
								{option.label}
							</span>
						</div>
					</MultiSelect>
				</Field>
			{:else if field.type === 'date'}
				<Field label={_(field.label)} position="top" let:id>
					<DatePicker
						{id}
						bind:value={$values[field.key]}
						label={_(field.label)}
						{format} />
				</Field>
			{:else if field.type === 'files'}
				<Field label={_(field.label)} position="top">
					<FilesControl {field} {values} />
				</Field>
			{/if}
		{/each}
	{/if}
</div>

<style>
	.editor {
		background: var(--wx-background-light);
		width: 0;
		min-width: 0;
		height: auto;
		transition: width 0.1s, min-width 0.1s;
		overflow-y: auto;
		overflow-x: hidden;
		z-index: 999999;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2),
			0px 1px 3px rgba(0, 0, 0, 0.1);
	}
	.editor-open {
		padding: var(--wx-default-padding);
		width: var(--wx-kanban-editor-width);
		min-width: var(--wx-kanban-editor-width);
	}
	.editor-controls {
		display: flex;
		width: 100%;
		margin-bottom: 15px;
	}
	:global(.editor-controls > .btn:not(:last-child)) {
		margin-right: 5px;
	}
	.multiselect-option {
		display: flex;
		align-items: center;
	}
	.multiselect-label {
		padding: 0 5px;
	}
	.combo-option {
		display: flex;
	}
	.color {
		border-radius: 50%;
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}
	/* [todo] create similar functionality in svelte-ws */
	:global(.wx-list-item) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 5px 0;
	}
	:global(.wx-hover) {
		cursor: pointer;
	}
	:global(.kanban > .editor label) {
		padding-left: 0;
	}</style>
