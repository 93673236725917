<script lang="ts">import Icon from "../../lib/Icon.svelte";
import UserIcon from "../../lib/UserIcon.svelte";
import { uid } from "@xbs/lib-state";
;
import { shortFormat } from "@xbs/lib-kanban";
import { getContext } from "svelte";
export let cardFields;
export let cardShape;
const _ = getContext("wx-i18n")._;
function getFooterValues(cardFields, shape) {
    var _a, _b;
    let values = {};
    const { show } = shape === null || shape === void 0 ? void 0 : shape.users;
    const usersIds = cardFields.users;
    if (show && usersIds) {
        const usersConfig = usersIds.reduce((c, id) => {
            const user = shape.users.values.find(v => v.id === id);
            if (user) {
                c.push(user);
            }
            return c;
        }, []);
        let users = usersConfig.map(user => {
            let label = user.label || "";
            return Object.assign(Object.assign({}, user), { label, id: user.id || uid() });
        });
        const maxUsersCount = 2;
        if (usersConfig.length > maxUsersCount) {
            users = users.splice(0, maxUsersCount);
            users.push({
                label: `+${usersConfig.length - users.length}`,
                id: "$total",
            });
        }
        if (users === null || users === void 0 ? void 0 : users.length) {
            values.users = users;
        }
    }
    const { show: showStartDate } = shape.start_date || {};
    const { show: showEndDate } = shape.end_date || {};
    let { end_date, start_date } = cardFields;
    if (showStartDate || showEndDate) {
        if (start_date) {
            values.startDate = shortFormat(start_date, _("lang"));
        }
        if (end_date) {
            values.endDate = shortFormat(end_date, _("lang"));
        }
    }
    if (((_a = shape === null || shape === void 0 ? void 0 : shape.attached) === null || _a === void 0 ? void 0 : _a.show) && ((_b = cardFields.attached) === null || _b === void 0 ? void 0 : _b.length)) {
        values.attached = cardFields.attached.length;
    }
    return values;
}
$: footerValues = getFooterValues(cardFields, cardShape);
</script>

<div class="footer" class:with-content={!!Object.keys(footerValues).length}>
	<div class="users">
		{#if footerValues.users}
			{#each footerValues.users as user (user.id)}
				<UserIcon data={user} noTransform={user.id === '$total'} />
			{/each}
		{/if}
	</div>
	{#if footerValues.attached}
		<div class="attached">
			<Icon name="paperclip" />
			{footerValues.attached}
		</div>
	{/if}
	{#if footerValues.endDate || footerValues.startDate}
		<div class="date">
			<Icon name="calendar" />
			{#if footerValues.startDate}
				<span class="date-value">{footerValues.startDate}</span>
			{/if}
			{#if footerValues.endDate && footerValues.startDate}-{/if}
			{#if footerValues.endDate}
				<span class="date-value">{footerValues.endDate}</span>
			{/if}
		</div>
	{/if}
</div>

<style>
	.footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.with-content {
		padding-top: var(--wx-kanban-card-field-padding);
	}
	.users {
		display: flex;
	}
	.date {
		display: flex;
		align-items: center;
	}
	.date-value {
		padding: 0 5px;
	}

	.attached {
		display: flex;
		align-items: center;
	}</style>
