<script lang="ts">import { createEventDispatcher, getContext } from "svelte";
import { wx } from "@xbs/svelte-wx";
import Icon from "./../lib/Icon.svelte";
;
const { Dropdown, List } = wx;
export let row = {
    id: "default",
    label: "default",
    collapsed: false,
};
export let collapsable = true;
export let edit = true;
const _ = getContext("wx-i18n").getGroup("kanban");
const dispatch = createEventDispatcher();
function handleRowClick() {
    row.collapsed = !(row === null || row === void 0 ? void 0 : row.collapsed);
}
let renaming = false;
let rowLabel = null;
function endRenaming() {
    if (renaming && (rowLabel === null || rowLabel === void 0 ? void 0 : rowLabel.trim())) {
        dispatch("action", {
            action: "update-row",
            data: {
                id: row.id,
                label: rowLabel,
            },
        });
    }
    renaming = false;
    rowLabel = null;
}
function handleRowRename() {
    if (!edit) {
        return;
    }
    renaming = true;
}
function handleLabelInput(e) {
    rowLabel = e.target.value;
}
function handleInputKeypress(e) {
    if (e.charCode === 13) {
        endRenaming();
    }
}
function focus(node) {
    node.focus();
}
let menuOpen;
function handleMenuClick() {
    menuOpen = true;
}
function handleMenuAction(action) {
    // [todo] rename actions after wx is fixed
    if (action === 1) {
        handleRowRename();
    }
    if (action === 2) {
        dispatch("action", {
            action: "delete-row",
            data: {
                id: row.id,
            },
        });
    }
    menuOpen = null;
}
</script>

<div class="row" class:collapsed={row.collapsed}>
	<div class="label">
		{#if collapsable}
			<div class="label-icon" on:click={handleRowClick} />
			<div class="label-text" on:dblclick={handleRowRename}>
				{#if renaming}
					<input
						type="text"
						class="input"
						value={row.label}
						on:input={handleLabelInput}
						on:keypress={handleInputKeypress}
						on:blur={endRenaming}
						use:focus />
				{:else}{row.label}{/if}
			</div>
			{#if !renaming && edit}
				<div class="menu">
					<Icon name="dots-h" click={handleMenuClick} />
					{#if menuOpen}
						<Dropdown
							cancel={() => (menuOpen = false)}
							width="auto">
							<List
								click={handleMenuAction}
								data={[{ icon: 'edit', label: _('Rename'), id: 1 }, { icon: 'delete', label: _('Delete'), id: 2 }]}
								let:obj>
								<div class="menu-item">
									<Icon name={obj.icon} />
									<span>{obj.label}</span>
								</div>
							</List>
						</Dropdown>
					{/if}
				</div>
			{/if}
		{/if}
		<div class="label-line" />
	</div>
	<div class="content">
		{#if !row.collapsed}
			<slot />
		{/if}
	</div>
</div>

<style>
	.row {
		display: flex;
		flex-direction: column;
		min-width: 100%;
	}
	.label {
		padding: 0 var(--wx-default-padding);
		display: flex;
		flex-direction: row;
		align-items: center;

		position: relative;
	}
	.label-text {
		max-width: 50%;
		margin-right: 10px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.label-line {
		height: 1px;
		background: var(--wx-border-color);
		flex: 1 1 auto;
	}
	.label-icon {
		background: no-repeat center center;
		background-image: var(--wx-close-btn-icon);
		width: 30px;
		height: 30px;
		cursor: pointer;
	}
	.content {
		display: flex;
		flex-direction: row;
	}

	.collapsed .label-icon {
		background-image: var(--wx-open-btn-icon);
	}

	.input {
		padding: var(--wx-input-padding);
		outline: none;
		flex: 1;
		font: var(--wx-font);
		color: var(--wx-color);
		width: 100%;
		box-sizing: border-box;

		border: var(--wx-input-border);
		border-radius: var(--wx-input-border-radius);
		background-color: transparent;
	}
	.input:focus {
		border: 1px solid var(--wx-input-focus-color);
	}

	.menu {
		margin-right: 10px;
	}
	.menu:hover {
		background: var(--wx-background);
	}
	.menu-item {
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 12px;
		min-width: 120px;
	}
	.menu-item span {
		padding: 0 5px 0 15px;
	}</style>
