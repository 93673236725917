<script>
	export let options;
	export let value;

</script>

<div class="line">
	{#each options as option}
		<button
			class="tab"
			class:active={option.id == value}
			on:click={() => (value = option.id)}>
			<i class="tab-icon {option.icon}" />
			{option.label}
		</button>
	{/each}
</div>

<style>
	.line {
		border-bottom: 1px solid var(--wx-border-color);
		display: inline-block;
		margin: 5px 0 10px 0;
	}
	.tab {
		border: 1px solid transparent;
		border-bottom: 1px solid var(--wx-border-color);
		background: var(--wx-back-color);
		padding: 8px 20px 5px 20px;
		cursor: pointer;
		color: var(--wx-disabled-color);

		margin: 0 5px;
		position: relative;
		top: 1px;
	}
	.active {
		border: 1px solid var(--wx-border-color);
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		border-top: 2px solid var(--wx-border-color);
		border-bottom: 1px solid var(--wx-back-color);
		color: var(--wx-color);
	}
	.tab:hover {
		color: var(--wx-color);
	}</style>
