<script>
	import { themes } from "@xbs/svelte-wx";
	const { Meta } = themes;
	const SLOTS = $$props.$$slots;

</script>

{#if SLOTS && SLOTS.default}
	<Meta>
		<div class="wx-default" style="height:100%;width:100%;">
			<slot />
		</div>
	</Meta>
{/if}

<style global>
	:global(.wx-default) :global(*) {
		box-sizing: border-box;
	}
	:global(.wx-default),
	:global(.wx-portal) {
		/* common */
		--wx-step: 4px;
		--wx-color-primary: #0288d1;
		--wx-color-secondary: #4d4d4d;
		--wx-color-danger: #ff5252;
		--wx-color-success: #0ab169;

		--wx-font: 400 14px Roboto, sans-serif;

		--wx-font-color: rgba(0, 0, 0, 0.7);
		--wx-font-color-light: rgba(0, 0, 0, 0.3);
		--wx-icon-color: rgba(0, 0, 0, 0.5);

		--wx-background: #f1f1f1;
		--wx-background-light: #ffffff;

		/* set wx variables */
		--wx-color: var(--wx-font-color);
		--wx-back-color: var(--wx-background-light);
		--wx-secondary-color-hover: var(--wx-background);
		/* set wx variables */

		--wx-border-color: #dfdfdf;

		--wx-default-padding: 12px;
		--wx-default-border: 1px solid var(--wx-border-color);
		--wx-font-size: 16px;
		--wx-font-size-md: 15px;
		--wx-font-size-sm: 14px;

		/* Kanban */
		--wx-kanban-column-width: 300px;
		--wx-kanban-toolbar-height: 56px;
		--wx-kanban-toolbar-align: center;
		--wx-kanban-toolbar-justify: flex-start;

		--wx-kanban-toolbar-controls-justify: flex-end;
		--wx-kanban-toolbar-controls-flex: 1 1 auto;

		--wx-kanban-column-header-height: 44px;

		--wx-kanban-card-field-padding: 12px;
		--wx-card-background: var(--wx-background-light);
		--wx-card-border: var(--wx-default-border);
		--wx-card-border-radius: 6px;

		--wx-field-width: 100%;
		--wx-user-icon-size: 40px;

		--wx-progress-height: 4px;

		--wx-kanban-header-height: 68px;

		--wx-kanban-editor-width: 300px;
		/* End Kanban */

		--wx-primary-color: #2a7fff;
		--wx-primary-color-hover: #246cd9;
		--wx-primary-font-color: #fff;

		/* buttons */
		--wx-button-font: 500 14px Roboto;
		--wx-button-primary-font-color: #fff;
		--wx-button-danger-font-color: #ff5252;
		--wx-button-primary-color: #2a7fff;
		--wx-button-primary-color-hover: #246cd9;
		--wx-button-danger-color: transparent;
		--wx-button-danger-color-hover: rgba(255, 82, 82, 0.2);
		--wx-button-radius: 30px;

		/* shadows */
		--wx-top-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1),
			0px 1px 2px rgba(0, 0, 0, 0.1);

		/* input */
		--wx-label-font: 500 14px Roboto;
		/* --wx-input-focus-color: #2a7fff; */

		/* icons */
		--wx-add-btn-icon: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.52734 0.671875H6.47266C6.37891 0.671875 6.33203 0.71875 6.33203 0.8125V6.33203H1.09375C1 6.33203 0.953125 6.37891 0.953125 6.47266V7.52734C0.953125 7.62109 1 7.66797 1.09375 7.66797H6.33203V13.1875C6.33203 13.2812 6.37891 13.3281 6.47266 13.3281H7.52734C7.62109 13.3281 7.66797 13.2812 7.66797 13.1875V7.66797H12.9062C13 7.66797 13.0469 7.62109 13.0469 7.52734V6.47266C13.0469 6.37891 13 6.33203 12.9062 6.33203H7.66797V0.8125C7.66797 0.71875 7.62109 0.671875 7.52734 0.671875Z' fill='%2300C7B5' fill-opacity='0.54'/%3E%3C/svg%3E ");
		--wx-open-btn-icon: url("data:image/svg+xml,%3Csvg width='5' height='10' viewBox='0 0 5 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 10L5 5L0 0V10Z' fill='%235F5F5F' fill-opacity='0.54'/%3E%3C/svg%3E%0A");
		--wx-close-btn-icon: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0L5 5L10 0H0Z' fill='%235F5F5F' fill-opacity='0.54'/%3E%3C/svg%3E%0A");
		--wx-close-sb-icon: url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.0344 1.42188L12.6281 0.015625L7.04999 5.59375L1.47186 0.015625L0.0656128 1.42188L5.64374 7L0.0656128 12.5781L1.47186 13.9844L7.04999 8.40625L12.6281 13.9844L14.0344 12.5781L8.45624 7L14.0344 1.42188Z' fill='black' fill-opacity='0.54'/%3E%3C/svg%3E%0A");
		--wx-show-more-icon: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.175 0.158203L5 3.97487L8.825 0.158203L10 1.3332L5 6.3332L0 1.3332L1.175 0.158203Z' fill='%235F5F5F'/%3E%3C/svg%3E ");
		--wx-calendar-icon: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.8203 15.3203H2.17969V6.17969H13.8203V15.3203ZM11.3203 0.320312V2H4.67969V0.320312H3V2H2.17969C1.6849 2 1.28125 2.15625 0.96875 2.46875C0.65625 2.78125 0.5 3.1849 0.5 3.67969V15.3203C0.5 15.7891 0.65625 16.1927 0.96875 16.5312C1.30729 16.8438 1.71094 17 2.17969 17H13.8203C14.2891 17 14.6797 16.8438 14.9922 16.5312C15.3307 16.1927 15.5 15.7891 15.5 15.3203V3.67969C15.5 3.1849 15.3307 2.78125 14.9922 2.46875C14.6797 2.15625 14.2891 2 13.8203 2H13V0.320312H11.3203ZM12.1797 9.5H8V13.6797H12.1797V9.5Z' fill='black' fill-opacity='0.5'/%3E%3C/svg%3E ");
		--wx-remove-link-icon: url("data:image/svg+xml,%3Csvg width='12' height='15' viewBox='0 0 12 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 13.3203C1 13.7891 1.15625 14.1927 1.46875 14.5312C1.80729 14.8438 2.21094 15 2.67969 15H9.32031C9.78906 15 10.1797 14.8438 10.4922 14.5312C10.8307 14.1927 11 13.7891 11 13.3203V3.32031H1V13.3203ZM3.03125 7.38281L4.24219 6.21094L6 8.00781L7.75781 6.21094L8.92969 7.38281L7.17188 9.17969L8.92969 10.9375L7.75781 12.1094L6 10.3516L4.24219 12.1094L3.07031 10.9375L4.82812 9.17969L3.03125 7.38281ZM8.92969 0.820312L8.07031 0H3.92969L3.07031 0.820312H0.179688V2.5H11.8203V0.820312H8.92969Z' fill='black' fill-opacity='0.5'/%3E%3C/svg%3E ");
	}</style>
