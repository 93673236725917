<script>
	import { uid } from "@xbs/lib-core-dom";
	import Dropdown from "./Dropdown.svelte";

	export let colors = [
		"#00a037",
		"#df282f",
		"#fd772c",
		"#6d4bce",
		"#b26bd3",
		"#c87095",
		"#90564d",
		"#eb2f89",
		"#ea77c0",
		"#777676",
		"#a9a8a8",
		"#9bb402",
		"#e7a90b",
		"#0bbed7",
		"#038cd9",
	];
	export let value = "";
	export let id = uid();
	export let clear = true;
	export let placeholder = "";

	let popup;

	function selectColor(color) {
		value = color;
		popup = null;
	}
	function handleClear() {
		value = null;
	}

</script>

<div class="layout" on:click={() => (popup = true)}>
	{#if value}
		<div
			class="color selected"
			style="background-color: {value || '#00a037'}" />
	{:else}
		<div class="empty selected" />
	{/if}

	<input {value} readonly {id} {placeholder} />
	{#if clear && value}
		<i class="clear wxi-close" on:click|stopPropagation={handleClear} />
	{/if}

	{#if popup}
		<Dropdown cancel={() => (popup = null)} width="auto">
			<div class="colors">
				<div
					class="empty"
					on:click|stopPropagation={() => selectColor('')} />
				{#each colors as color}
					<div
						class="color"
						style="background-color: {color}"
						on:click|stopPropagation={() => selectColor(color)} />
				{/each}
			</div>
		</Dropdown>
	{/if}
</div>

<style>
	.layout {
		position: relative;
		display: flex;
		align-items: center;
	}

	.selected {
		position: absolute;
		top: 2px;
		left: 0;
		bottom: 2px;
	}
	.colors {
		display: grid;
		grid-template-columns: repeat(8, 1fr);
		gap: 8px;
		padding: 8px;
	}

	.color {
		width: 20px;
		height: 20px;
		border-radius: 4px;
		cursor: pointer;
	}

	input {
		padding: 4px 4px 4px 24px;
		outline: none;
		font: var(--wx-font);
		color: var(--wx-color);

		border: none;
		border-bottom: 1px solid transparent;
		background-color: transparent;
	}
	input:focus {
		border-bottom: 1px solid var(--wx-input-focus-color);
	}

	.empty {
		box-sizing: border-box;
		width: 20px;
		height: 20px;
		border-radius: 4px;
		cursor: pointer;
		border: var(--wx-input-border);
		background-color: transparent;
		background: linear-gradient(
			to top left,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) calc(50% - 0.8px),
			rgb(255, 0, 0) 50%,
			rgba(0, 0, 0, 0) calc(50% + 0.8px),
			rgba(0, 0, 0, 0) 100%
		);
	}

	.clear {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		height: 20px;

		position: relative;
		right: 20px;

		cursor: pointer;
	}
	.clear:hover {
		background: var(--wx-secondary-back-color);
	}</style>
