<script>
	import { uid } from "@xbs/lib-core-dom";

	export let value = "";
	export let id = uid();
	export let placeholder = null;

</script>

<textarea bind:value {id} {placeholder} />

<style>
	textarea {
		padding: var(--wx-input-padding);
		padding-bottom: 0;

		outline: none;
		height: 85px;
		flex: 1;
		font: var(--wx-font);
		color: var(--wx-color);
		width: 100%;
		box-sizing: border-box;

		border: var(--wx-input-border);
		border-radius: var(--wx-input-border-radius);
		background-color: transparent;
	}
	textarea:focus {
		border: 1px solid var(--wx-input-focus-color);
	}</style>
