<script>
	import { uid } from "@xbs/lib-core-dom";

	export let label;
	export let position = "left";

	let id = uid();

</script>

<div class={position}>
	<label for={id}>{label}</label>
	<slot {id} />
</div>

<style>
	.left {
		margin: 4px 0 8px;
		display: flex;
		width: var(--wx-field-width);
		justify-content: space-between;
		align-items: center;
	}
	.top {
		display: block;
		width: var(--wx-field-width);
		margin-bottom: 8px;
	}
	.top label {
		display: block;
		font-size: 13px;
	}
	label {
		padding-left: 5px;
		line-height: 24px;
		width: 80px;
		flex-shrink: 0;
		font-weight: 500;
		color: var(--wx-font-color);
	}</style>
