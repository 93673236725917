<script lang="ts">import { createEventDispatcher, onMount, getContext } from "svelte";
import { wx } from "@xbs/svelte-wx";
import { uid } from "@xbs/lib-state";
import Icon from "./Icon.svelte";
const { Dropdown, List } = wx;
const _ = getContext("wx-i18n").getGroup("kanban");
export let value = "";
export let id = uid();
export let readonly = false;
export let focus = false;
export let placeholder = _("Search");
export let searchResults = null;
const dispatch = createEventDispatcher();
let searchFocused = false;
let input;
if (focus) {
    onMount(() => input.focus());
}
let searchEl;
function handlePopupClose(e) {
    if (!searchEl.contains(e.target)) {
        searchFocused = false;
        value = "";
    }
}
function handleSearchFocus() {
    searchFocused = true;
    dispatch("action", { action: "search-focus" });
}
function handleSearchBlur() {
    dispatch("action", { action: "search-blur" });
}
function handleSearchResultClick(id) {
    dispatch("action", { action: "result-click", id });
    searchFocused = false;
}
function handleClear() {
    value = "";
}
</script>

<div class="search" tabindex={1} on:click|stopPropagation bind:this={searchEl}>
	<div class="search-icon">
		<Icon name="search" />
	</div>
	<input
		bind:value
		bind:this={input}
		id={`${id}`}
		{readonly}
		{placeholder}
		on:focus={handleSearchFocus}
		on:blur={handleSearchBlur} />
	{#if !!value}
		<div class="close-icon">
			<Icon name="close" clickable click={handleClear} />
		</div>
	{/if}
	{#if searchFocused}
		<Dropdown cancel={handlePopupClose} width="auto">
			<div class="search-popup">
				{#if $$slots?.default}
					<div class="settings">
						<slot />
					</div>
				{/if}
				{#if searchResults}
					<div class="results">
						<List
							click={handleSearchResultClick}
							data={searchResults}
							let:obj>
							<div class="list-item">{obj.label}</div>
						</List>
					</div>
				{:else}
					<div class="list-item no-results">{_('No results')}</div>
				{/if}
			</div>
		</Dropdown>
	{/if}
</div>

<style>
	.search {
		position: relative;
		min-width: 300px;
		cursor: pointer;
	}
	.search-popup {
		min-width: 300px;
		max-height: 300px;
		overflow: auto;
	}
	input {
		padding: 4px;
		padding-left: 28px;
		padding-right: 26px;

		outline: none;
		flex: 1;
		font: var(--wx-font);
		color: var(--wx-color);
		width: 100%;
		box-sizing: border-box;

		border: none;
		background-color: transparent;
	}
	input:focus {
		border: none;
	}
	.search-icon {
		position: absolute;
		top: calc(50% - 10px);
		left: 4px;
	}
	.close-icon {
		position: absolute;
		top: calc(50% - 10px);
		right: 4px;
	}
	.settings {
		border-bottom: var(--wx-default-border);
	}
	.list-item {
		padding-left: 28px;
		height: 32px;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.no-results {
		color: rgba(0, 0, 0, 0.5);
		height: 36px;
		cursor: initial;
	}
	.results {
		margin: 8px 0;
	}</style>
