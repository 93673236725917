<script lang="ts">export let data = {
    label: "",
    path: "",
};
export let noTransform = false;
$: firstLetters = data.label
    .split(" ")
    .map(name => name[0])
    .join("");
</script>

<div class="user">
	{#if data.path}
		<img src={data.path} alt={data.label} />
	{:else if noTransform}{data.label}{:else}{firstLetters}{/if}
</div>

<style>
	.user {
		width: var(--wx-user-icon-size);
		height: var(--wx-user-icon-size);
		border-radius: 50%;
		border: solid var(--wx-background-light) 2px;
		background: var(--wx-background);

		color: var(--wx-font-color);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: var(--wx-font-size-sm);
		overflow: hidden;
	}

	.user img {
		display: block;
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		   object-fit: cover;
	}

	.user:nth-child(2) {
		transform: translateX(-10px);
	}
	.user:nth-child(3) {
		transform: translateX(-20px);
	}
	.user:nth-child(4) {
		transform: translateX(-30px);
	}</style>
