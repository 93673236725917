<script>
	export let icon = "";
	export let checked = false;

</script>

<button class:pressed={checked} on:click={() => (checked = !checked)}>
	{#if icon}<i class={icon} />{/if}
</button>

<style>
	button {
		padding: 16px 18px;
		border: none;
		border-radius: 4px;

		cursor: pointer;
	}
	button:hover {
		background: rgb(221, 221, 221);
	}
	button.pressed {
		background: var(--wx-primary-color);
		color: var(--wx-back-color);
	}</style>
