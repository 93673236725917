<script>
	import { uid } from "@xbs/lib-core-dom";

	import Text from "./Text.svelte";
	import Dropdown from "./Dropdown.svelte";
	import Slider from "./Slider.svelte";

	export let value = "12:20";

	const id = uid();

	const maxH = 23;
	const maxM = 59;

	const update = (v, max) => (v < max ? v : max);

	let popup;

	const time = value.split(":");
	let h = update(time[0], maxH);
	let m = update(time[1], maxM);

	$: {
		h = format(h);
		m = format(m);
		value = `${h}:${m}`;
	}

	function click() {
		popup = true;
	}

	function format(v) {
		v = `${v}`.replace(/[^\d]/g, "");
		v = (v < 10 ? `0${v}` : v).slice(-2);
		return v;
	}

	function cancel() {
		popup = null;
	}

</script>

<div class="input" on:click={click}>
	<Text {id} bind:value readonly={true} />
	<i class="icon wxi-clock" />
	{#if popup}
		<Dropdown {cancel} width={'unset'}>
			<div class="wrapper">
				<div class="timer">
					<input
						class="digit"
						bind:value={h}
						on:blur={() => (h = update(h, maxH))} />
					<div class="separator">:</div>
					<input
						class="digit"
						bind:value={m}
						on:blur={() => (m = update(m, maxM))} />
				</div>
				<Slider label="Hours" bind:value={h} max={maxH} />
				<Slider label="Minutes" bind:value={m} max={maxM} />
			</div>
		</Dropdown>
	{/if}
</div>

<style>
	.input {
		position: relative;
		display: flex;
		align-items: center;
	}

	.wrapper {
		padding: 10px 20px;
	}

	.timer {
		display: flex;
		text-align: center;
		justify-content: center;
		margin-bottom: 10px;
	}

	.digit {
		display: block;
		width: 50px;
		padding: 4px;

		border: none;
		border-bottom: 1px solid #ddd;

		font: var(--wx-font);
		font-size: 24px;
		text-align: center;

		color: var(--wx-color);
		background-color: transparent;
		outline: none;
	}

	.digit:focus {
		border-bottom: 1px solid var(--wx-input-focus-color);
	}

	.separator {
		font-size: 24px;
		line-height: 38px;
	}

	.icon {
		position: absolute;
		right: 6px;
	}</style>
