<script>
	import { onMount } from "svelte";
	import Button from "./Button.svelte";

	export let value = "";
	export let save;
	export let cancel;

	let input;
	let originalValue;

	onMount(() => {
		originalValue = value;
		input.select();
		input.focus();
	});

	function callSave(close) {
		save(value, close);
	}
	function callCancel() {
		value = originalValue;
		cancel();
	}

	function hotkeys(ev) {
		if (ev.key === "Enter") callSave();
		if (ev.key === "Escape") callCancel();
	}

</script>

<div class="line">
	<Button click={() => callSave(true)}>Save</Button>
	<input bind:this={input} type="text" bind:value on:keydown={hotkeys} />
</div>

<style>
	.line {
		display: flex;
		width: 100%;
	}
	input {
		margin-left: -4px;
		padding-left: 10px;
		width: 100%;
		/* height: 36px; */
		outline: none;
		border: none;
	}</style>
