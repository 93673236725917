<script>
	import { writable } from "svelte/store";
	import { uid, dateFormat } from "@xbs/lib-core-dom";

	import Text from "./Text.svelte";
	import Dropdown from "./Dropdown.svelte";
	import Button from "./Button.svelte";

	import Calendar from "./datepicker/Calendar.svelte";

	export let value = { start: null, end: null };
	export let id = uid();

	let popup;

	// two-way bound stores
	const leftCurrent = writable(
		value && value.start ? new Date(value.start) : new Date()
	);
	const rightCurrent = writable($leftCurrent);
	leftCurrent.subscribe(l => {
		const r = new Date(l);
		r.setMonth(r.getMonth() + 1);
		if (r.valueOf() != $rightCurrent.valueOf()) rightCurrent.set(r);
	});
	rightCurrent.subscribe(r => {
		const l = new Date(r);
		l.setMonth(l.getMonth() - 1);
		if (l.valueOf() != $leftCurrent.valueOf()) leftCurrent.set(l);
	});

	function cancel(e) {
		e.stopPropagation();

		if (value && value.start) {
			leftCurrent.set(new Date(value.start));
		}

		popup = null;
	}

	function selectDate(e, date) {
		if (date) {
			leftCurrent.set(new Date(date));

			if (!value) value = { start: null, end: null };
			if (value.end || !value.start)
				value = {
					start: new Date($leftCurrent),
					end: null,
				};
			else value.end = new Date($leftCurrent);
		} else {
			leftCurrent.set(new Date());
			value = null;
		}
	}

	let formattedValue;
	$: {
		formattedValue = value
			? value.start
				? dateFormat(value.start) +
				  (value.end ? ` - ${dateFormat(value.end)}` : "")
				: dateFormat(value)
			: value;
	}

</script>

<svelte:window on:scroll={cancel} />

<div class="layout" on:click={() => (popup = true)}>
	<Text
		value={formattedValue}
		{id}
		readonly={true}
		inputStyle={'cursor: pointer; text-overflow: ellipsis; padding-right: 18px;'} />
	<i class="icon wxi-calendar" />
	{#if popup}
		<Dropdown {cancel} width={'unset'}>
			<div class="calendar">
				<div class="calendars">
					<div class="half">
						<Calendar
							bind:value
							bind:current={$leftCurrent}
							part={'left'} />
					</div>
					<div class="half">
						<Calendar
							bind:value
							bind:current={$rightCurrent}
							part={'right'} />
					</div>
				</div>
				<div class="buttons">
					<div class="done">
						<Button type={'primary'} click={cancel}>Done</Button>
					</div>
					<Button type={'link'} click={e => selectDate(e)}>
						Clear
					</Button>
					<Button
						type={'link'}
						click={e => selectDate(e, new Date())}>
						Today
					</Button>
				</div>
			</div>
		</Dropdown>
	{/if}
</div>

<style>
	.layout {
		position: relative;
		width: 200px;
	}

	.calendar {
		height: auto;
		width: 380px;
		padding: 4px;
		cursor: default;
	}

	.calendars {
		display: flex;
	}

	.half {
		flex: 1;
	}

	.buttons {
		margin-right: 6px;
		display: grid;
		grid-template-columns: repeat(7, 1fr);
	}

	.done {
		grid-column-start: 4;
		grid-column-end: 6;
	}

	.icon {
		position: absolute;
		right: 4px;
		top: 8px;
	}</style>
