<script>
	import { createEventDispatcher } from "svelte";
	import { uid } from "@xbs/lib-core-dom";

	const dispatch = createEventDispatcher();

	export let id = uid();
	export let label = "";
	export let value = "";

</script>

<div>
	<input
		type="checkbox"
		{id}
		bind:checked={value}
		on:change={() => dispatch('change', { value })} />
	<label for={id}>{label}</label>
</div>

<style>
	div {
		display: inline-block;
	}
	input {
		width: 0;
		height: 0;
		opacity: 0;
		position: absolute;
	}
	label {
		position: relative;
		padding-left: 20px;
		line-height: 20px;
		cursor: pointer;
	}
	label:before {
		content: "";
		position: absolute;
		width: 17px;
		height: 17px;
		border: var(--wx-input-border);
		border-radius: 3px;
		top: -1px;
		left: 0;
		background-color: var(--wx-back-color);
	}
	input:checked + label:before {
		background: var(--wx-primary-color);
		border-color: transparent;
	}
	input:checked + label:after {
		content: "";
		position: absolute;
		left: 5px;
		top: 4px;
		width: 9px;
		height: 6px;
		border: solid var(--wx-back-color);
		border-width: 0 0 2px 2px;
		transform: rotate(-45deg);
		box-sizing: border-box;
	}</style>
