<script>
	import { getContext } from "svelte";
	import { delegateClick } from "@xbs/lib-core-dom";
	import { getDuodecade } from "@xbs/lib-core-dom";

	import Button from "../Button.svelte";
	import { default as enLocale } from "../../locales/data/en";
	import wrapper from "../../locales/wrapper";

	const locale = getContext("wx-i18n") || wrapper(enLocale);
	const _ = locale.getGroup("wx");

	export let value;
	export let current;
	export let cancel;
	export let part;

	let years;
	let year;
	$: {
		if (part !== "normal" && value) {
			if (part === "left" && value.start)
				year = value.start.getFullYear();
			else if (part === "right" && value.end)
				year = value.end.getFullYear();
			else year = current.getFullYear();
		} else {
			year = current.getFullYear();
		}

		const { start, end } = getDuodecade(current.getFullYear());
		years = [];
		for (let y = start; y <= end; ++y) {
			years.push(y);
		}
	}

	const selectYears = {
		click: selectYear,
	};
	function selectYear(year) {
		if (year) {
			current.setFullYear(year);
			current = current;
		}

		if (part === "normal") value = new Date(current);

		cancel();
	}

</script>

<div class="years" use:delegateClick={selectYears}>
	{#each years as y}
		<div class="year" class:current={year == y} data-id={y}>{y}</div>
	{/each}
</div>
<div class="buttons">
	<Button type="link" click={() => selectYear()}>{_('Close')}</Button>
</div>

<style>
	.years {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
	}
	.year {
		text-align: center;
		padding: 6px 0;
		margin: 6px 0;
		cursor: pointer;
		border-radius: 12px;
	}
	.buttons {
		display: grid;
	}
	.current {
		background: var(--wx-link-color);
		color: var(--wx-primary-font-color);
	}

	.year:not(.current) {
		transition: background-color 550ms;
	}

	.year:not(.current):hover {
		background-color: var(--wx-secondary-back-color);
	}</style>
