<script>
	import { getContext } from "svelte";
	import { delegateClick, sameMonth } from "@xbs/lib-core-dom";

	import { days as weekdaysEn } from "../../locales/data/en";

	export let value;
	export let current;
	export let cancel;
	export let part;

	const locale = getContext("wx-i18n");
	const weekdays = locale ? locale.__("__dates", "days") : weekdaysEn;

	let days;
	let date;
	let ranges = part !== "normal";
	$: {
		if (part == "normal") date = [value ? value.valueOf() : 0];
		else
			date = value
				? [
						value.start ? value.start.valueOf() : 0,
						value.end ? value.end.valueOf() : 0,
				  ]
				: [0, 0];

		const start = getStart();
		const end = getEnd();
		const curMonth = current.getMonth();
		days = [];
		for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
			const day = {
				day: d.getDate(),
				in: d.getMonth() === curMonth,
				date: d.valueOf(),
			};

			let css = "";
			css += !day.in ? " inactive" : "";
			css += date.indexOf(day.date) > -1 ? " selected" : "";

			if (ranges) {
				const s = day.date == date[0];
				const e = day.date == date[1];
				if (s && !e) css += " left";
				else if (e && !s) css += " right";

				if (day.date > date[0] && day.date < date[1]) css += " inrange";
			}

			css += isWeekEnd(d) ? " weekend" : "";

			days.push({ ...day, css });
		}
	}

	function isWeekEnd(date) {
		const d = date.getDay();
		return d === 0 || d === 6;
	}

	function getStart() {
		const monthStart = new Date(current);
		monthStart.setDate(1);
		const start = new Date(monthStart);
		start.setDate(start.getDate() - start.getDay());
		return start;
	}
	function getEnd() {
		const monthEnd = new Date(current);
		monthEnd.setMonth(monthEnd.getMonth() + 1);
		if (sameMonth(current, monthEnd)) monthEnd.setDate(0);
		else
			do {
				monthEnd.setDate(monthEnd.getDate() - 1);
			} while (!sameMonth(current, monthEnd));

		const end = new Date(monthEnd);
		end.setDate(end.getDate() + 6 - end.getDay());
		return end;
	}

	const selectDates = {
		click: selectDate,
	};

	function selectDate(date, e) {
		e.stopPropagation();

		if (date) {
			current = new Date(date);
			if (part === "normal") value = new Date(current);
			else {
				if (!value) value = { start: null, end: null };
				if (value.end || !value.start)
					value = {
						start: new Date(current),
						end: null,
					};
				else {
					value.end = new Date(current);
					if (value.end < value.start)
						[value.start, value.end] = [value.end, value.start];
				}
			}
		} else {
			value = null;
			current = new Date();
		}

		if (part === "normal") cancel();
	}

</script>

<div class="weekdays">
	{#each weekdays as day}
		<div class="weekday">{day}</div>
	{/each}
</div>
<div class="days" use:delegateClick={selectDates}>
	{#each days as day (day.date)}
		<div class="day {day.css}" class:out={!day.in} data-id={day.date}>
			{day.day}
		</div>
	{/each}
</div>

<style>
	.weekdays {
		display: flex;
		border-bottom: 1px solid var(--wx-border-color);
		margin-bottom: 2px;
	}

	.weekday {
		flex: 1;
	}

	.weekday,
	.day {
		text-align: center;
		padding: 5px 0;
		font: var(--wx-small-font);
	}

	.days {
		display: grid;
		grid-template-columns: repeat(7, 1fr);
	}

	.day {
		border-radius: 25px;
	}

	.day:not(.out):not(.selected) {
		cursor: pointer;
		transition: background-color 550ms;
	}

	.day:not(.out):not(.selected):hover {
		background-color: var(--wx-secondary-back-color);
	}

	.out {
		color: var(--wx-disabled-color);
	}

	.selected:not(.out) {
		background-color: var(--wx-primary-color);
		color: var(--wx-primary-font-color);
	}

	.selected.right:not(.out) {
		border-radius: 0 25px 25px 0;
	}
	.selected.left:not(.out) {
		border-radius: 25px 0 0 25px;
	}

	.inrange:not(.out) {
		border-radius: 0;
		background-color: var(--wx-selected-color);
	}

	.day.weekend:not(.selected):not(.out) {
		color: var(--wx-link-color);
	}

	.day.inactive {
		pointer-events: none;
	}</style>
