<script>
	import { uid } from "@xbs/lib-core-dom";

	export let value = 0;
	export let id = uid();

</script>

<input type="number" bind:value {id} />

<style>
	input {
		padding: var(--wx-input-padding);
		outline: none;
		flex: 1;
		font: var(--wx-font);
		color: var(--wx-color);

		border: none;
		border-bottom: 1px solid transparent;
		background-color: transparent;
	}
	input:focus {
		border-bottom: 1px solid var(--wx-input-focus-color);
	}</style>
