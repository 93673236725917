<script>
	import { locateID } from "@xbs/lib-core-dom";

	import Dropdown from "./Dropdown.svelte";
	import CheckBox from "./Checkbox.svelte";

	export let options = [];
	export let values = [];
	export let key = "label";

	let popup, list;
	let items = {};
	let selected = [];

	let navItem;
	let navInd;

	let search = "";
	let inputValue = "";

	$: list = options.filter(i =>
		i[key].toLowerCase().includes(search.toLowerCase())
	);

	$: selected = options.filter(i => values.includes(i.id));

	function show() {
		popup = true;
		navInd = 0;
		navItem = list[navInd];
	}

	function remove(id) {
		values = values.filter(i => i !== id);
	}

	function input() {
		if (!popup) show();
		search = inputValue || "";
	}

	function cancel() {
		popup = null;
		search = "";
		inputValue = "";
	}

	function select(ev) {
		const id = locateID(ev);
		if (id) {
			values = values.includes(id)
				? values.filter(i => i !== id)
				: [...values, id];
		}
	}

	function check(id) {
		values = values.includes(id)
			? values.filter(i => i !== id)
			: [...values, id];
	}

	function move(ev) {
		const id = locateID(ev);
		if (id) {
			navInd = list.findIndex(a => a.id === id);
			navItem = list[navInd];
		}
	}

	function navigate(dir) {
		navInd += dir;

		if (navInd > list.length - 1) {
			navInd = list.length - 1;
		} else if (navInd < 0) {
			navInd = 0;
		}

		navItem = list[navInd];

		items[navInd].scrollIntoView({ block: "nearest" });
	}

	function keySelect() {
		const id = navItem.id;

		values = values.includes(id)
			? values.filter(i => i !== id)
			: [...values, id];
	}

	function keydown(ev) {
		switch (ev.code) {
			case "Space":
				popup ? cancel() : show();
				break;

			case "Tab":
				if (popup) {
					cancel();
				}
				break;

			case "Enter":
				popup ? keySelect() : show();
				break;

			case "ArrowDown":
				popup ? navigate(1) : show();
				break;

			case "ArrowUp":
				popup ? navigate(-1) : show();
				break;

			case "Escape":
				cancel();
				break;

			default:
				break;
		}
	}

</script>

<div class="layout">
	<div class="wrapper" class:active={popup} on:click={show}>
		{#each selected as tag (tag.id)}
			<div class="tag">
				<span class="label">{tag[key]}</span>
				<i
					class="wxi-close"
					on:click|stopPropagation={() => remove(tag.id)} />
			</div>
		{/each}
		<div class="select">
			<input
				type="text"
				class="input"
				bind:value={inputValue}
				on:input={input}
				on:keydown={keydown} />
			<i class="wxi-angle-down" />
		</div>
	</div>

	{#if popup}
		<Dropdown {cancel}>
			<div class="list" on:click={select} on:mousemove={move}>
				{#if list.length}
					{#each list as data, index (data.id)}
						<div
							bind:this={items[index]}
							class="item"
							class:navigate={navItem && navItem.id === data.id}
							data-id={data.id}>
							<CheckBox
								on:click={() => check(data.id)}
								value={values.includes(data.id)} />
							<slot option={data} />
						</div>
					{/each}
				{:else}
					<div class="no-data">No data</div>
				{/if}
			</div>
		</Dropdown>
	{/if}
</div>

<style>
	.layout {
		position: relative;
		width: 400px;
	}

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		padding: 8px;
		border: var(--wx-input-border);
	}

	.wrapper.active {
		border-color: var(--wx-input-focus-color);
	}

	.select {
		position: relative;
		flex: 1;
		min-width: 100px;
	}

	.tag {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 8px;
		border: var(--wx-input-border);
		border-radius: 4px;
	}

	.input {
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		padding: var(--wx-input-padding);
		border: var(--wx-input-border);
		outline: none;
		font: var(--wx-font);
		color: var(--wx-color);
		cursor: pointer;
	}

	.list {
		max-height: 250px;
		background-color: var(--wx-back-color);
		overflow-y: auto;
	}

	.item {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 8px;
		cursor: pointer;
	}

	.item.navigate {
		background-color: var(--wx-secondary-back-color);
	}

	.no-data {
		padding: 8px;
	}

	.wxi-angle-down {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 10px;
		pointer-events: none;
	}

	.wxi-close {
		cursor: pointer;
		font-size: 18px;
		line-height: 10px;
	}</style>
