<script>
	import { locateID } from "@xbs/lib-core-dom";

	export let data = [];
	export let click;

	function handleClick(e) {
		const id = locateID(e);
		if (id && click) click(id, e);
	}

</script>

<div class="items" on:click={handleClick}>
	{#each data as obj (obj.id)}
		<div class="item" data-id={obj.id}>
			<slot {obj}>
				<div class="content">{obj.label}</div>
			</slot>
		</div>
	{/each}
</div>

<style>
	.items {
		width: 100%;
		font: var(--wx-font);
	}
	.content {
		padding: 12px;
		cursor: pointer;
		box-sizing: border-box;
		height: 40px;
		width: 100%;
	}
	.item:hover {
		background: var(--wx-secondary-color-hover);
	}</style>
