<script>
	import { getContext } from "svelte";
	import { sameMonth } from "@xbs/lib-core-dom";

	import Button from "../Button.svelte";
	import Header from "./Header.svelte";
	import Month from "./Month.svelte";
	import Year from "./Year.svelte";
	import Duodecade from "./Duodecade.svelte";

	import { default as enLocale } from "../../locales/data/en";
	import wrapper from "../../locales/wrapper";

	const locale = getContext("wx-i18n") || wrapper(enLocale);
	const _ = locale.getGroup("wx");

	export let value;
	export let current;
	export let cancel = null;
	export let part = "normal";

	let type = "month";
	const types = {
		month: {
			component: Month,
			next: nextMonth,
			prev: prevMonth,
			cancel: cancelMonth,
		},
		year: {
			component: Year,
			next: nextYear,
			prev: prevYear,
			cancel: backToMonth,
		},
		duodecade: {
			component: Duodecade,
			next: nextDuodecade,
			prev: prevDuodecade,
			cancel: backToYear,
		},
	};

	function prevMonth() {
		let newCurrent = new Date(current);
		newCurrent.setMonth(current.getMonth() - 1);
		while (sameMonth(current, newCurrent)) {
			newCurrent.setDate(newCurrent.getDate() - 1);
		}
		current = newCurrent;
	}
	function nextMonth() {
		current.setMonth(current.getMonth() + 1);
		current = current;
	}
	function prevYear() {
		current.setFullYear(current.getFullYear() - 1);
		current = current;
	}
	function nextYear() {
		current.setFullYear(current.getFullYear() + 1);
		current = current;
	}
	function prevDuodecade() {
		current.setFullYear(current.getFullYear() - 12);
		current = current;
	}
	function nextDuodecade() {
		current.setFullYear(current.getFullYear() + 12);
		current = current;
	}

	function cancelMonth() {
		if (value) {
			if (part === "normal") current = new Date(value);
		}
		type = "month";
		if (cancel) cancel();
	}
	function backToMonth() {
		type = "month";
	}
	function backToYear() {
		type = "year";
	}

	function selectDate(e, date) {
		e.stopPropagation();
		if (date) {
			current = new Date(date);
			value = new Date(current);
		} else {
			value = null;
			current = new Date();
		}
		if (part === "normal") cancelMonth();
	}

</script>

<div class="calendar">
	<Header
		date={current}
		{part}
		bind:type
		prev={types[type].prev}
		next={types[type].next} />
	<div class="body">
		<svelte:component
			this={types[type].component}
			bind:value
			bind:current
			{part}
			cancel={types[type].cancel} />
		{#if type === 'month' && part === 'normal'}
			<div class="buttons">
				<Button type={'link'} click={e => selectDate(e)}>
					{_('Clear')}
				</Button>
				<Button type={'link'} click={e => selectDate(e, new Date())}>
					{_('Today')}
				</Button>
			</div>
		{/if}
	</div>
</div>

<style>
	.calendar {
		height: auto;
		width: 185px;
		padding: 4px;
		cursor: default;
	}

	.body {
		padding: 0 6px;
	}

	.buttons {
		float: right;
		margin-bottom: 4px;
	}</style>
