<script lang="ts">var _a;
import DefaulthTheme from "../themes/DefaultTheme.svelte";
import Row from "./Row.svelte";
import Column from "./Column.svelte";
import Editor from "./Editor.svelte";
import ColumnsHeader from "./ColumnsHeader.svelte";
;
;
import { EventBusRouter } from "@xbs/lib-state";
import { createEventDispatcher, getContext, onDestroy, onMount, setContext, } from "svelte";
import { writable } from "svelte/store";
;
import { connectStores, getApi, dndAction, selection, keyManager, defaultCardShape, StateStore, DataStore, getAreaId, locale, en, elementsIds, } from "@xbs/lib-kanban";
;
export let columns;
export let rows = null;
export let cards;
export let cardShape = defaultCardShape;
export let editorShape = null;
export let editorAutoSave = true;
export let cardTemplate = null;
export let readonly = false;
export let columnKey = "column";
export let rowKey = "";
const i18nContext = getContext("wx-i18n");
if (!i18nContext) {
    setContext("wx-i18n", locale(en));
}
else if (!((_a = i18nContext === null || i18nContext === void 0 ? void 0 : i18nContext.data) === null || _a === void 0 ? void 0 : _a.kanban)) {
    i18nContext.extend(en);
}
const dispatch = createEventDispatcher();
// [TODO] maybe, find more elegant way
onMount(() => {
    if (!document.querySelector(".wx-portal")) {
        const portal = document.createElement("div");
        portal.classList.add("wx-portal");
        document.body.appendChild(portal);
    }
});
onDestroy(() => {
    var _a;
    (_a = document.querySelector(".wx-portal")) === null || _a === void 0 ? void 0 : _a.remove();
});
const dataStore = new DataStore(x => writable(x));
const stateStore = new StateStore(x => writable(x));
let lastInRoute = new EventBusRouter(dispatch);
connectStores(dataStore, stateStore, lastInRoute);
export const api = getApi(dataStore, stateStore, lastInRoute);
stateStore.init({
    dropAreaItemsCoords: null,
    dropAreasCoords: null,
    dragItemsCoords: null,
    dragItemId: null,
    before: null,
    overAreaId: null,
    overAreaMeta: null,
    selected: null,
    search: null,
});
dataStore.init({
    columnKey,
    rowKey,
    columns: columns || null,
    rows: rows || null,
    cards,
    cardsMap: {},
    cardsMeta: null,
    cardShape,
    editorShape,
    areasMeta: null,
});
const handleAction = ({ detail: { action, data } }) => api.exec(action, data);
let storeColumns, storeRowKey, storeRows, storeCards, cardsMap, storeCardShape, storeEditorShape, storeCardsMeta;
$: {
    dataStore.init({
        columnKey,
        rowKey,
        columns: columns || null,
        rows: rows || null,
        cards,
        cardsMap: {},
        cardsMeta: null,
        cardShape,
        editorShape,
        areasMeta: null,
    });
    const dataState = dataStore.getReactive();
    storeColumns = dataState.columns;
    storeRows = dataState.rows;
    storeRowKey = dataState.rowKey;
    storeCards = dataState.cards;
    storeCardShape = dataState.cardShape;
    storeEditorShape = dataState.editorShape;
    cardsMap = dataState.cardsMap;
    storeCardsMeta = dataState.cardsMeta;
}
const state = stateStore.getReactive();
const movedCardId = state.dragItemId;
const overCardId = state.before;
const overColId = state.overAreaId;
const dropAreasCoords = state.dropAreasCoords;
const dragItemsCoords = state.dragItemsCoords;
const selected = state.selected;
$: movedCardCoords = $dragItemsCoords && $dragItemsCoords[$movedCardId];
$: selectedCard = api.getCard(($selected === null || $selected === void 0 ? void 0 : $selected.length) === 1 && $selected[0]);
let edit, add, select, dnd;
$: {
    if (typeof readonly === "object") {
        edit = readonly === null || readonly === void 0 ? void 0 : readonly.edit;
        add = readonly === null || readonly === void 0 ? void 0 : readonly.add;
        select = readonly === null || readonly === void 0 ? void 0 : readonly.select;
        dnd = readonly === null || readonly === void 0 ? void 0 : readonly.dnd;
    }
    else {
        edit = add = select = dnd = readonly !== true;
    }
    if (!edit) {
        $storeCardShape.menu = $storeCardShape.menu || {};
        $storeCardShape.menu.show = false;
    }
}
function handleSelectAction(_name, data) {
    const { itemId, groupMode } = data;
    if (itemId) {
        api.exec("select-card", {
            id: itemId,
            groupMode,
        });
    }
    else if ($selected === null || $selected === void 0 ? void 0 : $selected.length) {
        api.exec("unselect-card", {
            id: null,
        });
    }
}
function handleDndActions(name, action) {
    api.exec(name, action);
}
function handleHotkey(_name, data) {
    const { hotkey } = data;
    switch (hotkey) {
        case "delete":
            if ($selected === null || $selected === void 0 ? void 0 : $selected.length) {
                $selected.map(id => {
                    api.exec("delete-card", {
                        id,
                    });
                });
            }
            break;
    }
}
</script>

<DefaulthTheme>
	<div
		class="kanban"
		class:dragged={!!$movedCardId}
		use:selection={{ onAction: handleSelectAction, readonly: select === false }}
		use:dndAction={{ onAction: handleDndActions, api, readonly: dnd === false }}
		use:keyManager={{ onAction: handleHotkey, readonly: edit === false }}>
		<div class="content-wrapper" data-kanban-id={elementsIds.content}>
			<div class="content">
				{#if $storeColumns && $storeCards.length}
					<ColumnsHeader
						columns={$storeColumns}
						on:action={handleAction}
						{edit} />

					{#each $storeRows as row (row.id)}
						<Row
							{row}
							collapsable={!!$storeRowKey}
							on:action={handleAction}
							{edit}>
							{#each $storeColumns as column (column.id)}
								<Column
									on:action={handleAction}
									{column}
									{row}
									overCardId={$overCardId}
									overColId={$overColId}
									movedCardId={$movedCardId}
									{movedCardCoords}
									selected={$selected}
									dropAreasCoords={$dropAreasCoords}
									cards={$cardsMap[getAreaId(column.id, row.id)]}
									cardShape={$storeCardShape}
									{cardTemplate}
									cardsMeta={$storeCardsMeta}
									{add} />
							{/each}
						</Row>
					{/each}
				{/if}
			</div>
		</div>
		{#if edit}
			<Editor
				selectedCard={!$movedCardId && selectedCard}
				editorShape={$storeEditorShape}
				autoSave={editorAutoSave}
				on:action={handleAction} />
		{/if}
	</div>
</DefaulthTheme>

<style>
	.kanban * {
		box-sizing: border-box;
		font-family: var(--wx-font);
	}

	.kanban {
		display: flex;
		flex-direction: row;
		position: relative;

		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;

		background: var(--wx-background);
		color: var(--wx-font-color);
		overflow: hidden;
	}
	.dragged * {
		-webkit-user-select: none;
		   -moz-user-select: none;
		    -ms-user-select: none;
		        user-select: none;
	}
	.dragged .content-wrapper {
		overflow: hidden;
	}
	.content-wrapper {
		display: flex;
		flex: 1 1 auto;
		overflow: auto;
		position: relative;
	}
	.content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex: 1 1 auto;
	}

	:global(.wx-portal .dragged-card) {
		font-family: Roboto, sans-serif;
		color: var(--wx-font-color);
		width: var(--wx-kanban-column-width);
		min-height: 70px;
		position: fixed;
		z-index: 999999;
		-webkit-user-select: none;
		   -moz-user-select: none;
		    -ms-user-select: none;
		        user-select: none;
		pointer-events: none;

		box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
			0 15px 12px rgba(0, 0, 0, 0.22);
	}
	:global(.wx-portal .dragged-card:after) {
		content: var(--wx-dragged-cards-count);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		background: var(--wx-primary-color);
		color: #fff;
		position: absolute;
		border-radius: 50%;
		top: -10px;
		right: -10px;
		z-index: 1;
	}
	:global(.wx-ondrag) {
		overflow: hidden;
	}</style>
