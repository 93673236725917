<script lang="ts">;
;
export let cardFields;
export let dragging = false;
export let selected = false;
export let cardShape;
export let cardTemplate;
export let meta = null;
</script>

<div
	class="card"
	class:hidden={dragging}
	class:selected
	class:dimmed={meta?.dimmed}
	data-drag-item={cardFields.id}>
	<svelte:component
		this={cardTemplate}
		{cardFields}
		{dragging}
		{selected}
		{cardShape}
		on:action />
</div>

<style>
	.card {
		box-sizing: border-box;

		background: var(--wx-card-background);
		border: var(--wx-card-border);
		border-radius: var(--wx-card-border-radius);

		width: 100%;
		min-height: 70px;
		margin: 10px 0;

		position: relative;
		font-size: 14px;

		display: flex;
		flex-direction: column;
	}
	.dimmed {
		opacity: 0.2;
	}
	.hidden {
		display: none;
	}

	.selected {
		border: 1px solid var(--wx-primary-color);
	}</style>
