<script lang="ts">import { getContext } from "svelte";
import { wx } from "@xbs/svelte-wx";
import { form } from "@xbs/lib-svelte";
;
import Search from "../Search.svelte";
const { Select } = wx;
const _ = getContext("wx-i18n").getGroup("kanban");
export let api;
export let showOptions = true;
$: cardsMeta = api && api.getStores().data.getReactive().cardsMeta;
let searchResults = null;
$: {
    if ($cardsMeta) {
        searchResults = Object.keys($cardsMeta).reduce((total, key) => {
            if ($cardsMeta[key].found) {
                total.push(api === null || api === void 0 ? void 0 : api.getCard(key));
            }
            return total;
        }, []);
        if (!searchResults.length) {
            searchResults = null;
        }
    }
}
const search = form({ value: "", by: null }, ({ value, by }) => {
    api.exec("set-search", {
        value,
        by,
    });
});
let changeHandler;
$: {
    if (api && !changeHandler) {
        changeHandler = (config) => {
            if ((config === null || config === void 0 ? void 0 : config.value) !== $search.value ||
                (config === null || config === void 0 ? void 0 : config.by) !== ($search === null || $search === void 0 ? void 0 : $search.by)) {
                search.reset(config);
            }
        };
        api.on("set-search", changeHandler);
    }
}
const searchOptions = [
    { id: null, label: _("Everywhere") },
    { id: "label", label: _("Label") },
    { id: "description", label: _("Description") },
];
function handleSearchAction({ detail }) {
    const { id, action } = detail;
    switch (action) {
        case "result-click":
            api.exec("select-card", { id });
            break;
        case "search-focus":
            if ($search.value) {
                api.exec("set-search", {
                    value: $search.value,
                    by: $search.by,
                });
            }
            break;
    }
}
</script>

<Search
	bind:value={$search.value}
	{searchResults}
	on:action={handleSearchAction}>
	{#if showOptions}
		<div class="select">
			<div class="title">{_('Search in')}:</div>
			<Select bind:value={$search.by} options={searchOptions} />
		</div>
	{/if}
</Search>

<style>
	.title {
		display: block;
		padding-right: 2px;
		font: var(--wx-label-font);
		color: var(--wx-label-font-color);
	}
	.select {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 36px;
		padding-left: 28px;
		padding-right: 8px;

		--wx-input-border: none;
	}</style>
