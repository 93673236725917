<script>
	import { afterUpdate } from "svelte";

	export let left = 0;
	export let top = 0;
	export let area = null;
	export let cancel;

	$: {
		if (area) {
			top = area.top + area.height;
			left = area.left;
		} else {
			// do nothing
		}
	}

	let self;

	function fixPosition() {
		const bodyCoords = document.body.getBoundingClientRect();
		const nodeCoords = self.getBoundingClientRect();

		if (nodeCoords.right >= bodyCoords.right) {
			left = bodyCoords.right - nodeCoords.width;
		}

		if (nodeCoords.bottom >= bodyCoords.bottom) {
			top = bodyCoords.bottom - nodeCoords.height - 12;
		}
	}
	afterUpdate(() => fixPosition());

	function down(e) {
		if (!self.contains(e.target)) {
			if (cancel) cancel(e);
		}
	}

</script>

<svelte:body on:mousedown={down} />

<div bind:this={self} class="popup" style="top:{top}px;left:{left}px;">
	<slot />
</div>

<style>
	.popup {
		position: fixed;
		background: var(--wx-back-color);
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
		z-index: 5;
	}</style>
