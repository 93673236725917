<script>
	import { getContext } from "svelte";
	import { getDuodecade } from "@xbs/lib-core-dom";

	import { default as enLocale } from "../../locales/data/en";
	import wrapper from "../../locales/wrapper";

	const locale = getContext("wx-i18n") || wrapper(enLocale);
	const dates = locale.getGroup("__dates");

	export let date;
	export let type;
	export let prev;
	export let next;

	export let part;

	let month, year, label;
	$: {
		month = date.getMonth();
		year = date.getFullYear();

		switch (type) {
			case "month":
				label = `${monthNames[month]} ${year}`;
				break;
			case "year":
				label = year;
				break;
			case "duodecade": {
				const { start, end } = getDuodecade(year);
				label = `${start} - ${end}`;
				break;
			}
		}
	}

	const monthNames = dates("months");

	function changeType() {
		if (type === "month") type = "year";
		else if (type === "year") type = "duodecade";
	}

</script>

<div class="header">
	{#if part != 'right'}
		<i class="pager wxi-angle-left" on:click={prev} />
	{:else}<span class="spacer" />{/if}
	<span class="header-label" on:click={changeType}>{label}</span>
	{#if part != 'left'}
		<i class="pager wxi-angle-right" on:click={next} />
	{:else}<span class="spacer" />{/if}
</div>

<style>
	.header {
		display: flex;
	}

	.pager,
	.header-label {
		text-align: center;
		padding: 8px;
	}

	.pager {
		flex: 1;
		cursor: pointer;
		transition: background-color 550ms;
		border-radius: 25px;
	}

	.pager:hover {
		color: var(--wx-link-color);
		background-color: var(--wx-secondary-back-color);
	}

	.pager:active {
		background-color: var(--wx-selected-color);
	}

	.header-label {
		flex: 6;
		white-space: nowrap;
		color: var(--wx-link-color);
		cursor: pointer;
	}

	.spacer {
		flex: 1;
	}</style>
