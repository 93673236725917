<script>
	export let style = "";

</script>

<div class="bar" {style}>
	<slot />
</div>

<style>
	.bar {
		margin: 12px 0px 5px;
	}</style>
