<script>
	import { uid } from "@xbs/lib-core-dom";
	import { onMount } from "svelte";

	export let value = "";
	export let id = uid();
	export let focus = false;

	let input;
	if (focus) {
		onMount(() => input.focus());
	}

</script>

<input type="password" bind:value bind:this={input} {id} />

<style>
	input {
		padding: var(--wx-input-padding);
		outline: none;
		flex: 1;
		font: var(--wx-font);
		color: var(--wx-color);

		border: none;
		border-bottom: 1px solid transparent;
		background-color: transparent;
	}
	input:focus {
		border-bottom: 1px solid var(--wx-input-focus-color);
	}</style>
