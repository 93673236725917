<script>
	import { delegateClick } from "@xbs/lib-core-dom";
	import Button from "./Button.svelte";
	import Dropdown from "./Dropdown.svelte";

	export let type = "";
	export let shape = "round";
	export let label = "";
	export let click;
	export let options = [];

	let popup = null;

	const clickHandlers = {
		click: id => {
			popup = null;
			click(id);
		},
	};

</script>

<div class="layout">
	<Button {type} {shape} click={() => (popup = true)}>{label}</Button>
	{#if popup}
		<Dropdown cancel={() => (popup = null)}>
			<div class="wx-list list" use:delegateClick={clickHandlers}>
				{#each options as option (option.id)}
					<div class="wx-list-item" data-id={option.id}>
						<slot {option} />
					</div>
				{/each}
			</div>
		</Dropdown>
	{/if}
</div>

<style>
	.layout {
		position: relative;
	}</style>
