<script>
	import { uid } from "@xbs/lib-core-dom";
	export let label = "label";
	export let value = "";
	export let options = [];

	export let id = uid();

</script>

<select class="select" {id} bind:value>
	{#each options as option (option.id)}
		<option value={option.id}>{option[label]}</option>
	{/each}
</select>

<style>
	select {
		padding: var(--wx-input-padding);
		outline: none;
		flex: 1;
		font: var(--wx-font);
		color: var(--wx-color);
		width: 100%;

		border: var(--wx-input-border);
		border-radius: var(--wx-input-border-radius);
		background-color: transparent;
	}
	select:focus {
		border: 1px solid var(--wx-input-focus-color);
	}</style>
