<script>
	import { locateID } from "@xbs/lib-core-dom";
	import { onMount, tick, createEventDispatcher } from "svelte";

	import Dropdown from "./Dropdown.svelte";

	export let value;
	export let options = [];
	export let key = "label";

	const dispatch = createEventDispatcher();

	let popup;
	let list = [];

	let navItem;
	let navInd;

	let search = "";
	let inputValue = "";

	onMount(() => {
		if (value) {
			inputValue = options.find(a => a.id === value)[key] || "";
		}
	});

	$: filterOptions = search
		? options.filter(i =>
				i[key].toLowerCase().includes(search.toLowerCase())
		  )
		: [].concat(options);

	function show() {
		popup = true;
		navInd = value ? filterOptions.findIndex(a => a.id === value) : 0;
		navItem = filterOptions[navInd];
		tick().then(() => {
			if (list[navInd]) list[navInd].scrollIntoView({ block: "nearest" });
		});
	}

	function close() {
		search = "";
		popup = null;
		navInd = null;
		navItem = null;
	}

	function cancel() {
		if (filterOptions.length) {
			value = filterOptions[0].id;
			inputValue = filterOptions[0][key];
		} else {
			value = null;
			inputValue = "";
		}
		close();
	}

	function input() {
		if (!popup) show();
		search = inputValue || "";
	}

	function select(ev) {
		const id = locateID(ev);
		if (id) {
			value = id;
			inputValue = options.find(a => a.id === id)[key];
			dispatch("change", { value });
			close();
		}
	}

	function move(ev) {
		const id = locateID(ev);
		if (id) {
			navInd = filterOptions.findIndex(a => a.id === id);
			navItem = filterOptions[navInd];
		}
	}

	function keySelect() {
		const id = navItem.id;

		if (filterOptions.length) {
			value = id;
			inputValue = options.find(a => a.id === id)[key];
			dispatch("change", { value });
		} else {
			value = null;
			inputValue = "";
		}

		close();
	}

	function navigate(dir) {
		navInd += dir;

		if (navInd > filterOptions.length - 1) {
			navInd = filterOptions.length - 1;
		} else if (navInd < 0) {
			navInd = 0;
		}

		if (filterOptions.length) {
			navItem = filterOptions[navInd];
			list[navInd].scrollIntoView({ block: "nearest" });
		}
	}

	function keydown(ev) {
		switch (ev.code) {
			case "Space":
				popup ? close() : show();
				break;

			case "Tab":
				if (popup) {
					close();
				}
				break;

			case "Enter":
				popup ? keySelect() : show();
				break;

			case "ArrowDown":
				popup ? navigate(1) : show();
				break;

			case "ArrowUp":
				popup ? navigate(-1) : show();
				break;

			case "Escape":
				close();
				break;

			default:
				break;
		}
	}

</script>

<div class="layout">
	<input
		class="input"
		class:active={popup}
		bind:value={inputValue}
		on:click={show}
		on:input={input}
		on:keydown={keydown}
		tabindex="0" />
	<i class="icon wxi-angle-down" />

	{#if popup}
		<Dropdown {cancel}>
			<div class="list" on:click={select} on:mousemove={move}>
				{#if filterOptions.length}
					{#each filterOptions as data, index (data.id)}
						<div
							bind:this={list[index]}
							class="item"
							class:selected={value && value === data.id}
							class:navigate={navItem && navItem.id === data.id}
							data-id={data.id}>
							<slot option={data} />
						</div>
					{/each}
				{:else}
					<div class="no-data">No data</div>
				{/if}
			</div>
		</Dropdown>
	{/if}
</div>

<style>
	.layout {
		position: relative;
		width: 100%;
	}

	.input {
		box-sizing: border-box;
		width: 100%;
		min-height: 30px;
		padding: var(--wx-input-padding);
		border: var(--wx-input-border);
		outline: none;
		font: var(--wx-font);
		color: var(--wx-color);
		cursor: pointer;
	}

	.input.active {
		border-color: var(--wx-input-focus-color);
	}

	.list {
		max-height: 250px;
		background-color: var(--wx-back-color);
		overflow-y: auto;
	}

	.item {
		padding: 8px;
		cursor: pointer;
	}

	.item.navigate {
		background-color: var(--wx-secondary-back-color);
	}

	.item.selected {
		color: var(--wx-primary-font-color);
		background-color: var(--wx-primary-color);
	}

	.icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 10px;
		pointer-events: none;
	}

	.no-data {
		padding: 8px;
	}</style>
