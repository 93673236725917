<script>
	import { afterUpdate, onMount } from "svelte";

	export let data = [];
	export let values = [];

	onMount(() => {
		list[0] = data.filter(i => !values.includes(i.id));
		list[1] = data.filter(i => values.includes(i.id));
	});

	afterUpdate(() => {
		values = list[1].map(i => i.id);
	});

	const selected = [[], []];
	const list = [data, []];

	function select(from, id) {
		let arr = selected[from];
		arr = arr.includes(id) ? arr.filter(i => i !== id) : [...arr, id];
		selected[from] = arr;
	}

	function moveSingle(from, id) {
		const to = from ? 0 : 1;
		const d = data.find(i => i.id === id);

		list[from] = list[from].filter(i => i.id !== id);
		list[to] = [...list[to], d];
	}

	function moveSelected(from) {
		const to = from ? 0 : 1;
		const d = selected[from];

		list[from] = list[from].filter(i => !d.includes(i.id));
		list[to] = [...list[to], ...data.filter(i => d.includes(i.id))];

		selected[from] = [];
	}

	function moveAll(from) {
		const to = from ? 0 : 1;
		const d = list[from];

		list[to] = [...list[to], ...d];

		list[from] = [];
		selected[from] = [];
	}

</script>

<div class="layout">
	<div class="list">
		{#each list[0] as rec (rec.id)}
			<div
				class="item"
				class:active={selected[0].includes(rec.id)}
				on:click={() => select(0, rec.id)}
				on:dblclick={() => moveSingle(0, rec.id)}>
				{rec.name}
			</div>
		{/each}
	</div>

	<div class="controls">
		<div class="icon" on:click={() => moveAll(1)}>
			<i class="wxi-angle-dbl-left" />
		</div>
		<div class="icon" on:click={() => moveAll(0)}>
			<i class="wxi-angle-dbl-right" />
		</div>
		<div class="icon" on:click={() => moveSelected(1)}>
			<i class="wxi-angle-left" />
		</div>
		<div class="icon" on:click={() => moveSelected(0)}>
			<i class="wxi-angle-right" />
		</div>
	</div>

	<div class="list">
		{#each list[1] as rec (rec.id)}
			<div
				class="item"
				class:active={selected[1].includes(rec.id)}
				on:click={() => select(1, rec.id)}
				on:dblclick={() => moveSingle(1, rec.id)}>
				{rec.name}
			</div>
		{/each}
	</div>
</div>

<style>
	.layout {
		display: flex;
	}
	.list {
		width: 200px;
		max-height: 300px;
		background: var(--wx-secondary-back-color);
		overflow: auto;
	}

	.item {
		margin: 8px;
		padding: 8px 12px;
		border: 1px solid var(--wx-border-color);
		font: var(--wx-font);
		color: var(--wx-font-color);
		background: var(--wx-back-color);
		text-decoration: none;
		cursor: pointer;
		-webkit-user-select: none;
		   -moz-user-select: none;
		    -ms-user-select: none;
		        user-select: none;
	}

	.item:hover {
		font-weight: 500;
	}

	.item.active {
		font-weight: 500;
		box-shadow: inset 2px 0 var(--wx-input-focus-color);
	}

	.controls {
		padding: 0 20px;
		display: grid;
		grid-template-columns: 40px 40px;
		grid-template-rows: 40px 40px;
		grid-gap: 8px;
	}

	.icon {
		border: 1px solid var(--wx-border-color);
		border-radius: 4px;
		font-size: 16px;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
	}</style>
