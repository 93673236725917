<script>
	import { uid } from "@xbs/lib-core-dom";

	const id = uid();
	export let label = "";
	export let value = "";
	export let group = "";

</script>

<div>
	<input type="radio" {value} {id} bind:group />
	<label for={id}>{label}</label>
</div>

<style>
	div {
		margin: 10px 0;
	}

	input {
		width: 0;
		height: 0;
		opacity: 0;
		position: absolute;
	}

	label {
		position: relative;
		padding-left: 27px;
		line-height: 20px;
		cursor: pointer;
	}

	label:before {
		content: "";
		position: absolute;
		width: 18px;
		height: 18px;
		border: 1px solid rgba(30, 55, 90, 0.25);
		border-radius: 50%;
		top: -1px;
		left: 0;
		background-color: var(--wx-back-color);
	}

	input:checked + label:before {
		background: var(--wx-primary-color);
		border-color: transparent;
	}

	input:checked + label:after {
		content: "";
		position: absolute;
		left: 2px;
		top: 1px;
		width: 8px;
		height: 8px;
		border: 4px solid var(--wx-back-color);
		border-radius: 50%;
	}</style>
