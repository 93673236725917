<script>
	const SLOTS = $$props.$$slots;

</script>

{#if SLOTS && SLOTS.default}
	<div class="wx-meta-theme" style="height:100%">
		<slot />
	</div>
{/if}

<svelte:head>
	<link rel="stylesheet" href="https://cdn.dhtmlx.com/fonts/wxi/wx-icons.css" />
</svelte:head>

<style>
	:global(.wx-meta-theme) * {
		box-sizing: border-box;
	}

	:global(.wx-meta-theme) {
		font: 400 14px Roboto;

		--wx-font: 400 14px Roboto;
		--wx-small-font: 400 12px Roboto;
		--wx-back-color: #fff;
		--wx-secondary-back-color: #ebebeb;
		--wx-border-color: #e8eaee;
		--wx-color: #444;
		--wx-disabled-color: #aaa;

		--wx-icon-color: #aaa;
		--wx-active-icon-color: #444;

		--wx-primary-color: #2a7fff;
		--wx-primary-color-hover: #246cd9;
		--wx-primary-font-color: #fff;

		--wx-secondary-color-hover: #dedede;

		--wx-danger-font-color: #fff;
		--wx-danger-color-hover: #ca4242;
		--wx-danger-color: #ff5252;

		--wx-success-color: #0fd665;
		--wx-warning-color: #e2bb50;
		--wx-selected-color: #d5e6ff;

		--wx-header-color: #eee;
		--wx-header-font-color: #444;
		--wx-header-font-alt-color: #aaa;

		--wx-link-color: #2a7fff;
		--wx-popup-shadow: 1px 1px 10px 10px silver;
		--wx-layer-shadow: rgba(100, 100, 100, 0.2);
		--wx-card-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
		--wx-bottom-shadow: 0px 1px 3px 0px silver;

		--wx-field-width: 240px;
		--wx-input-focus-color: var(--wx-primary-color);
		--wx-input-border: 1px solid #dfdfdf;
		--wx-input-border-radius: 2px;
		--wx-input-padding: 6px 8px;
	}</style>
