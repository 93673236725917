<script>
	import { onMount } from "svelte";
	import { fade } from "svelte/transition";
	import Button from "./Button.svelte";

	export let title;
	export let ok;
	export let cancel;

	function keydown(e) {
		switch (e.code) {
			case "Enter":
				ok();
				break;
			case "Escape":
				cancel();
				break;
		}
	}

	let modal;
	onMount(() => {
		modal.focus();
	});

</script>

<div
	class="modal"
	bind:this={modal}
	transition:fade
	tabindex="0"
	on:keydown={keydown}>
	<div class="confirm">
		<div class="header">{title}</div>
		<div class="body">
			<slot />
		</div>
		<div class="buttons">
			<div class="button">
				<Button type={'plain'} click={cancel}>Cancel</Button>
			</div>
			<div class="button">
				<Button type={'primary'} click={ok}>OK</Button>
			</div>
		</div>
	</div>
</div>

<style>
	.confirm {
		background: var(--wx-back-color);
		padding: 20px;
		border-radius: 4px;
		max-width: 350px;
	}

	.header {
		font-weight: 500;
		font-size: 16px;
		margin-bottom: 18px;
		text-align: center;
		color: var(--wx-header-font-color);
	}

	.body {
		margin-bottom: 18px;
		min-width: 200px;
	}

	.modal {
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.5);
	}

	.buttons {
		display: flex;
		float: right;
	}

	.button {
		margin-left: 8px;
	}</style>
