<script>
	import { afterUpdate } from "svelte";

	export let position = "bottom";
	export let align = "start";
	export let autoFit = true;
	export let cancel = null;
	export let width = "100%";

	let node;
	let classes = "";

	afterUpdate(() => {
		if (autoFit) {
			const nodeCoords = node.getBoundingClientRect();
			const bodyCoords = document.body.getBoundingClientRect();

			if (nodeCoords.right >= bodyCoords.right) {
				position = "bottom";
				align = "end";
			}

			if (nodeCoords.bottom >= bodyCoords.bottom) {
				position = "top";
				align = "end";
			}
		}

		classes = align ? `${position}-${align}` : `${position}`;
	});

	function down(e) {
		if (!node.contains(e.target)) {
			if (cancel) cancel(e);
		}
	}

</script>

<svelte:body on:mousedown={down} />

<div bind:this={node} class="popup {classes}" style="width:{width}">
	<slot />
</div>

<style>
	.popup {
		position: absolute;
		min-height: 100%;
		background: var(--wx-back-color);
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
		z-index: 5;
	}

	.top {
		top: 0;
		left: 50%;
		transform: translate(-50%, -100%) translateY(-2px);
	}

	.top-start {
		top: 0;
		transform: translateY(-100%) translateY(-2px);
	}

	.top-end {
		top: 0;
		right: 0;
		transform: translateY(-100%) translateY(-2px);
	}

	.bottom {
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 100%) translateY(2px);
	}

	.bottom-start {
		bottom: 0;
		transform: translateY(100%) translateY(2px);
	}

	.bottom-end {
		bottom: 0;
		right: 0;
		transform: translateY(100%) translateY(2px);
	}

	.left {
		bottom: 50%;
		left: 0;
		transform: translate(-100%, 50%) translateX(-2px);
	}

	.left-start {
		top: 0;
		left: 0;
		transform: translateX(-100%) translateX(-2px);
	}

	.left-end {
		bottom: 0;
		left: 0;
		transform: translateX(-100%) translateX(-2px);
	}

	.right {
		bottom: 50%;
		right: 0;
		transform: translate(100%, 50%) translateX(2px);
	}

	.right-start {
		top: 0;
		right: 0;
		transform: translateX(100%) translateX(2px);
	}

	.right-end {
		bottom: 0;
		right: 0;
		transform: translateX(100%) translateX(2px);
	}</style>
