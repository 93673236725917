<script>
	import { getContext } from "svelte";
	import { delegateClick } from "@xbs/lib-core-dom";

	import Button from "../Button.svelte";
	import { default as enLocale } from "../../locales/data/en";
	import wrapper from "../../locales/wrapper";

	const locale = getContext("wx-i18n") || wrapper(enLocale);
	const dates = locale.getGroup("__dates");
	const _ = locale.getGroup("wx");

	export let value;
	export let current;
	export let cancel;
	export let part;

	const months = dates("monthsShort");

	let monthNum;
	$: {
		if (part !== "normal" && value) {
			if (part === "left" && value.start)
				monthNum = value.start.getMonth();
			else if (part === "right" && value.end)
				monthNum = value.end.getMonth();
			else monthNum = current.getMonth();
		} else {
			monthNum = current.getMonth();
		}
	}

	const selectMonths = {
		click: selectMonth,
	};
	function selectMonth(month) {
		if (month) {
			current.setMonth(month);
			current = current;
		}

		if (part === "normal") value = new Date(current);

		cancel();
	}

</script>

<div class="months" use:delegateClick={selectMonths}>
	{#each months as month, i}
		<div class="month" class:current={monthNum === i} data-id={i}>
			{month}
		</div>
	{/each}
</div>
<div class="buttons">
	<Button type="link" click={() => selectMonth()}>{_('Close')}</Button>
</div>

<style>
	.months {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
	}
	.month {
		text-align: center;
		padding: 6px 0;
		margin: 6px 0;
		cursor: pointer;
		border-radius: 12px;
	}
	.buttons {
		display: grid;
	}
	.current {
		background: var(--wx-link-color);
		color: var(--wx-primary-font-color);
	}

	.month:not(.current) {
		transition: background-color 550ms;
	}

	.month:not(.current):hover {
		background-color: var(--wx-secondary-back-color);
	}</style>
