<script>
	import { createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();

	export let pageSize = 20;
	export let total = 0;
	export let value = 1;

	let pageCount = 0,
		from = 0,
		to = 0;
	$: pageCount = Math.ceil(total / pageSize);
	$: {
		from = (value - 1) * pageSize;
		to = Math.min(value * pageSize, total);
		setTimeout(() => {
			dispatch("change", { value, from, to });
		}, 1);
	}

	function setActivePage(id) {
		switch (id) {
			case "first":
				value = 1;
				break;

			case "prev":
				value = Math.max(1, value - 1);
				break;

			case "next":
				value = Math.min(+value + 1, pageCount);
				break;

			case "last":
				value = pageCount;
				break;

			default:
				break;
		}
	}

</script>

<div class="pagination">
	<div class="left">
		<span>Rows per page: </span>
		<input class="rows-per-page" type="text" bind:value={pageSize} />
	</div>

	<div class="center">
		<i
			class="icon wxi-angle-dbl-left"
			on:click={() => setActivePage('first')} />
		<i class="icon wxi-angle-left" on:click={() => setActivePage('prev')} />
		<input class="active-page" type="text" bind:value />
		<i
			class="icon wxi-angle-right"
			on:click={() => setActivePage('next')} />
		<i
			class="icon wxi-angle-dbl-right"
			on:click={() => setActivePage('last')} />
	</div>

	<div class="total-pages">Total pages: {pageCount}</div>
</div>

<style>
	.pagination {
		display: flex;
		gap: 10px;
		align-items: center;
		padding: 20px;
	}

	.center {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.center .icon {
		padding: 5px;
		font-size: 16px;
		cursor: pointer;
	}

	.active-page,
	.rows-per-page {
		width: 50px;
		padding: var(--wx-input-padding);
		text-align: center;
		border: var(--wx-input-border);
		border-radius: var(--wx-input-border-radius);
	}</style>
