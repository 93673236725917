<script>
	import { uid } from "@xbs/lib-core-dom";

	export let label = "";
	export let min = 1;
	export let max = 100;
	export let value = 0;
	export let step = 1;

	let progress = 0;
	let bgStyle = "";

	const inactive = "#dbdbdb";
	const id = uid();

	$: {
		progress = ((value - min) / (max - min)) * 100 + "%";
		bgStyle = `background: linear-gradient(90deg, var(--wx-primary-color) 0% ${progress}, ${inactive} ${progress} 100%);`;

		if (typeof value !== "number" || isNaN(value)) value = 0;
	}

</script>

<div class="layout">
	<label class="label" for={id}>{label}</label>
	<input
		{id}
		class="range"
		type="range"
		{min}
		{max}
		{step}
		bind:value
		style={bgStyle} />
</div>

<style>
	.layout {
		margin-bottom: 5px;
	}

	.label {
		display: block;
		margin-bottom: 5px;
		font: var(--wx-label-font);
		color: var(--wx-label-font-color);
	}

	.range {
		width: 100%;
		background-color: var(--wx-secondary-back-color);
		-webkit-appearance: none;
		border-radius: 4px;
	}

	.range:focus {
		outline: none;
	}

	.range::-webkit-slider-runnable-track {
		border-radius: 3px;
		width: 100%;
		height: 4px;
		cursor: pointer;
	}

	.range::-webkit-slider-thumb {
		margin-top: -6px;
		width: 16px;
		height: 16px;
		background: var(--wx-primary-color);
		border: 2px solid var(--wx-back-color);
		border-radius: 50%;
		cursor: pointer;
		-webkit-appearance: none;
	}

	.range::-moz-range-track {
		background-color: transparent;
		border-top: 5px solid var(--wx-back-color);
		border-bottom: 5px solid var(--wx-back-color);
		width: 100%;
		height: 8px;
		cursor: pointer;
	}

	.range::-moz-range-thumb {
		width: 14px;
		height: 14px;
		background: var(--wx-primary-color);
		border-radius: 50%;
		cursor: pointer;
	}

	.range::-moz-range-progress {
		background-color: var(--wx-primary-color);
		height: 8px;
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
	}

	.range::-ms-track {
		background: transparent;
		border-color: transparent;
		border-width: 5px 0;
		color: transparent;
		width: 100%;
		height: 8px;
		cursor: pointer;
	}

	.range::-ms-fill-lower {
		background: var(--wx-primary-color);
		border: 0.2px solid var(--wx-primary-color);
		border-radius: 8px;
	}

	.range::-ms-fill-upper {
		background: var(--wx-secondary-back-color);
		border: 0.2px solid var(--wx-secondary-back-color);
		border-radius: 8px;
	}

	.range::-ms-thumb {
		width: 16px;
		height: 16px;
		background: var(--wx-primary-color);
		border: 2px solid var(--wx-back-color);
		border-radius: 50%;
		cursor: pointer;
		margin-top: 0px;
	}

	.range:focus::-ms-fill-lower {
		background: var(--wx-primary-color);
	}

	.range:focus::-ms-fill-upper {
		background: var(--wx-secondary-back-color);
	}</style>
